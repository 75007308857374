import {
    Alert,
    Button,
    Card,
    CardContent,
    Divider,
    Stack,
    Typography
} from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import userServices from "../../../../services/userServices";
import { useNavigate } from "react-router-dom";
import Label from "../../../../components/label";
import { ConfirmActionModal } from "../../../../components/modals/confirmActionModal";
import { useSnackbar } from "notistack";

import TrustDltWallet from "./TrustDltWallet";
import trustServices from "../../../../services/trustServise";

function TrustProfile({ trustDetails, url ,getTrustDetails }) {
    console.log(trustDetails)
    const user = useSelector(state => state.user.data);
    const navigate = useNavigate();

    const [actionModal, setActionModal] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const handleDeleteTrust = async (hufId) => {

        const res = await trustServices.removeTrust(hufId, user._id)
        if (res && res.success) {
            navigate("/dashboard/profile");
            getTrustDetails()
            enqueueSnackbar("Deleted Successfully", {
                variant: "success", anchorOrigin: { horizontal: "right", vertical: "top" },
                autoHideDuration: 3000,
            });
        }
        else {
            enqueueSnackbar("Something went wrong!", {
                variant: "error",
                anchorOrigin: { horizontal: "right", vertical: "top" },
                autoHideDuration: 3000,
            });
        }
    }


    return (
        trustDetails?.map((row) => {
            return (
                <Card 
                sx={{ 
                    margin: 1, 
                    padding: 2, 
                    minWidth: '30%', 
                    boxShadow: 1, 
                    ...((row.trust?.status =="KYC_APPROVED"||row.trust?.status =="APPROVED")&&{ display: "flex", width: "100%", justifyContent: 'space-between' })
                }}
            >
                    <ConfirmActionModal open={actionModal} setOpen={setActionModal} handleYes={() => handleDeleteTrust(row?.entityId)} data={row?.entityId} />
                    <CardContent sx={(row.trust?.status =="KYC_APPROVED"||row.trust?.status =="APPROVED")&&{width:"40%"}}>
                        <Stack rowGap={2}>
                            <Stack direction={'row'} spacing={2} justifyContent={"space-between"} alignItems={'center'}>
                                <Typography>
                                    {row?.trust?.status ?
                                        <Label sx={{ py: 2, px: 2, fontWeight: 600 }} variant="ghost" color={row?.trust?.status == 'PENDING' ? 'warning' : row?.trust?.status == 'REJECTED' ? 'error' : row?.trust?.status == 'APPROVED' ? 'success' : row?.trust?.status == 'KYC_COMPLETE' ? 'warning' :row?.trust?.status == 'KYC_APPROVED' ? 'success': ''}>
                                            {row?.trust?.status === "KYC_APPROVED" ? "KYC Approved" : row?.trust?.status}
                                        </Label> : "-"}
                                </Typography>
                                <Stack direction={'row'} spacing={1}>
                                    <Button disabled={row?.trust?.status !== "REJECTED"} variant="body2" sx={{ py: '8px !important', px: '5px !important', bgcolor: '#ff79711c' }} onClick={() => { navigate('/trust-registration', { state: { mode: "edit", trustData: row, url: url } }) }}>Edit</Button>
                                    <Button disabled={row?.trust?.status !== "REJECTED"} variant="body2" sx={{ py: '8px !important', px: '5px !important', bgcolor: '#ff79711c', '&:hover': { cursor: 'pointer' }, color: '#FF382D' }} onClick={() => { setActionModal(true) }}>
                                        Delete
                                    </Button>
                                </Stack>
                            </Stack>
                            <Divider></Divider>
                            <Stack direction={'row'} alignItems={'center'} spacing={2} justifyContent={"space-between"}>
                                <Typography variant="label">Trust Name</Typography>
                                <Typography variant="body2">{row?.trust?.trustName}</Typography>
                            </Stack>
                            <Stack direction={'row'} alignItems={'center'} spacing={2} justifyContent={"space-between"}>
                                <Typography variant="label">Account Holder Name</Typography>
                                <Typography variant="body2">{row?.trust?.bankInformation?.accHolderName}</Typography>
                            </Stack>
                            <Stack direction={'row'} alignItems={'center'} spacing={2} justifyContent={"space-between"}>
                                <Typography variant="label">PAN Number</Typography>
                                <Typography variant="body2">{row?.trust?.panCardNumber}</Typography>
                            </Stack>
                            <Stack direction={'row'} alignItems={'center'} spacing={2} justifyContent={"space-between"}>
                                <Typography variant="label">Account Number</Typography>
                                <Typography variant="body2">{row?.trust?.bankInformation?.accNumber}</Typography>
                            </Stack>
                            <Stack direction={'row'} alignItems={'center'} spacing={2} justifyContent={"space-between"}>
                                <Typography variant="label">IFSC Code</Typography>
                                <Typography variant="body2">{row?.trust?.bankInformation?.ifsc}</Typography>
                            </Stack>
                            <Stack direction={'row'} alignItems={'center'} spacing={2} justifyContent={"space-between"}>
                                <Typography variant="label">Account Type</Typography>
                                <Typography variant="body2">{row?.trust?.bankInformation?.accType}</Typography>
                            </Stack>
                            <Stack direction={'row'} alignItems={'center'} spacing={2} justifyContent={"space-between"}>
                                <Typography variant="label">Cancel Check Image </Typography>
                                <Typography variant="body2" component="a"
                                    href={`https://d2wn9fkjg79rzz.cloudfront.net/${row?.trust?.documents[1]?.docUrl}`}
                                    download={row?.trust?.documents[1]?.docName}
                                    sx={{
                                        color: 'blue',
                                        textDecoration: 'none',
                                        '&:hover': { textDecoration: 'underline' }
                                    }}
                                >
                                    Download
                                </Typography>
                            </Stack>
                            <Stack direction={'row'} alignItems={'center'} spacing={2} justifyContent={"space-between"}>
                                <Typography variant="label">Trust Deed</Typography>
                                <Typography variant="body2" component="a"
                                    href={`https://d2wn9fkjg79rzz.cloudfront.net/${row?.trust?.documents[2]?.docUrl}`}
                                    download={row?.trust?.documents[2]?.docName}
                                    sx={{
                                        color: 'blue',
                                        textDecoration: 'none',
                                        '&:hover': { textDecoration: 'underline' }
                                    }}
                                >
                                    Download
                                </Typography>
                            </Stack>

                            {/* blockchain acc */}
                        </Stack>

                    </CardContent>
                    {(row.trust?.status =="KYC_APPROVED"||row.trust?.status =="APPROVED") &&
                        <>
                            <Divider sx={{ borderColor: "#00000017", borderWidth: "1px" }}></Divider>
                            {
                                row.status !== "APPROVED" || row.status !== "KYC_APPROVED" ?
                                    <TrustDltWallet entityId={row?.entityId} getTrustDetails={()=>getTrustDetails()} /> : (
                                        <Alert severity="error" variant="outlined" sx={{width:"80%",marginLeft:"10px"}}>
                                            <Typography variant="body1" fontSize={14}>
                                                Once your KYC is approved, you can create a DLT account.
                                            </Typography>
                                        </Alert>
                                    )
                            }
                        </>
                    }
                </Card>
            )

        })

    )
}


export default TrustProfile
