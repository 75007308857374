import { Box, Button, Stack, Typography, Grid, CircularProgress, Backdrop, FormControlLabel, Radio, FormControl, FormLabel, RadioGroup, Divider, ToggleButton, ToggleButtonGroup, keyframes } from "@mui/material";
import Checkbox from '@mui/material/Checkbox';
import { LoadingButton } from "@mui/lab"
import React, { useEffect, useState } from "react";
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from "formik";

import FormField from "../../components/form/formField";
import FormFileField from "../../components/form/formFileField";
import FormAutoCompleted from "../../components/form/formAutoComplete";
import familymemberServices from "../../services/familymemberServices";
import { Update } from "@mui/icons-material";

const VALID_FILE_SIZE = 5000000;
const SUPPORTED_FORMATS = ["image/png", "image/jpeg", "application/pdf"];

export const ManualKYCForm = ({ setIsManual,  user, enqueueSnackbar, dispatch, handleClose, setAnimating, setOpenProgressBar, familymemberId, setUser, getallFamilyMember, familyMember,url }) => {
    const [panAvailabel, setPanAvailabel] = useState(familyMember?.isPanCard || false)

    const manualKycSchema = Yup.object().shape({
        panCardNumber: Yup.string().when("panAvailabel", {
            is: true,
            then: Yup.string()
                .matches(/^[A-Za-z]{5}[0-9]{4}[A-Za-z]$/, "Invalid PAN number format")
                .required("PAN No. is required."),
            otherwise: Yup.string().notRequired(),
        }),
        legalName: Yup.string()
            .matches(/^[A-Za-z\s]+$/, "Legal Name can only contain letters and spaces.")
            .required("Legal Name is required."),
        // ifscCode: Yup.string().required("IFSC code is required."),
        // accNumber: Yup.number().required("Account Number is required."),
        // aadhaar:Yup.string().required("Aadhaar No. is required.")
        addressProof: Yup.object().nullable().required("Please select address proof."),

        userAddress: Yup.object().shape({
            flatNo: Yup.string()
                .matches(/^[A-Za-z0-9, ]+$/, "Flat No can only contain letters, numbers, and commas.")
                .required("Flat No is required."),
            area: Yup.string()
            .matches(/^[A-Za-z0-9, ]+$/, "Area can only contain letters, numbers, and commas.")
            .required("Area is required."),
            landmark: Yup.string().matches(/^[A-Za-z0-9, ]+$/, "Landmark can only contain letters, numbers, and commas."),
            town: Yup.string()
                .matches(/^[A-Za-z\s]+$/, "Town can only contain letters.")
                .required("Town is required."),

            state: Yup.string().required("State is required."),
            pincode: Yup.string()
                .matches(/^\d{6}$/, "Pincode must have number and 6 digits.")
                .required("Pincode is required."),
        }),
        panCard: Yup.mixed().when('panAvailabel', {
            is: true,
            then: Yup.mixed().nullable()
                .test("FILE_REQUIRED", "Please upload Pan card document.", function (value) {
                    // If it's not a File object, return that file is required
                    if (!(value instanceof File)) {
                        return false;
                    }
                    
                    // For File objects, ensure they exist
                    return !!value;
                })
                .test("FILE_SIZE", "Upload a file below 5MB.", function (value) {
                    if (!value) return false;
                    
                    // Only check file size if it's a File object
                    if (value instanceof File) {
                        return value.size <= VALID_FILE_SIZE;
                    }
                    
                    // If it's not a File object, fail validation to force re-upload
                    return false;
                })
                .test("FILE_FORMAT", "Uploaded file has unsupported format.", function (value) {
                    if (!value) return false;
                    
                    // Only check format if it's a File object
                    if (value instanceof File) {
                        return SUPPORTED_FORMATS.includes(value.type);
                    }
                    
                    // If it's not a File object, fail validation to force re-upload
                    return false;
                }),
            otherwise: Yup.mixed().notRequired()
        }),
        addressProofFront: Yup.mixed().nullable().required("Please upload Address proof document.").test("FILE_SIZE", "Upload a file below 5MB.", value => value && value.size <= VALID_FILE_SIZE)
            .test("FILE_FORMAT", "Uploaded file has unsupported format.", value => value && SUPPORTED_FORMATS.includes(value.type)),
        addressProofBack: Yup.mixed().nullable().test("FILE_SIZE", "Upload a file below 5MB.", value => value ? value.size <= VALID_FILE_SIZE : true)
            .test("FILE_FORMAT", "Uploaded file has unsupported format.", value => value ? SUPPORTED_FORMATS.includes(value.type) : true),
    })
    const [frontSelectedImage, setFrontSelectedImage] = useState(null);
    const [backSelectedImage, setBackSelectedImage] = useState(null);
    const [panSelectedImage, setPanSelectedImage] = useState(null);
    const [validation, setValidation] = React.useState(manualKycSchema);
    

    const userType = user.citizenShip

    const allAddressProofOptions = [
        {
            title: "Aadhar Card",
            label: "Aadhar Card",
            value: "Aadhar Card",
            back: true
        },
        {
            title: "Voter Card",
            label: "Voter Card",
            value: "Voter Card",
            back: false
        },
        {
            title: "Passport",
            label: "Passport",
            value: "Passport",
            back: true
        },
        {
            title: "Driving license",
            label: "Driving license",
            value: "Driving license",
            back: false
        },
        {
            title: "Light bill",
            label: "Light bill",
            value: "Light bill",
            back: false
        },
    ];

    const [addressProofOptions, setAddressProofOptions] = useState(allAddressProofOptions);

     const states = [
            'Andhra Pradesh', 'Arunachal Pradesh', 'Assam', 'Bihar', 'Chhattisgarh',
            'Goa', 'Gujarat', 'Haryana', 'Himachal Pradesh', 'Jharkhand', 'Karnataka',
            'Kerala', 'Madhya Pradesh', 'Maharashtra', 'Manipur', 'Meghalaya', 'Mizoram',
            'Nagaland', 'Odisha', 'Punjab', 'Rajasthan', 'Sikkim', 'Tamil Nadu', 'Telangana',
            'Tripura', 'Uttar Pradesh', 'Uttarakhand', 'West Bengal',"Andaman and Nicobar Islands","Chandigarh",
            "Dadra and Nagar Haveli and Daman and Diu","Lakshadweep","Delhi","Puducherry","Jammu and Kashmir","Ladakh"
          ];
    
        const [stateOptions,setStateOptions]  = useState(states)
    


    useEffect(() => {
        // Conditionally filter options
        if (userType === "NRI" || userType === "FOREIGNER") {
            setAddressProofOptions([
                {
                    title: "Passport",
                    label: "Passport",
                    value: "Passport",
                    back: true,
                },
            ]);
        } else {
            setAddressProofOptions(allAddressProofOptions);
        }
    }, [user.citizenShip]);


    const formik = useFormik({
        initialValues: {
            panAvailabel: familyMember?.isPanCard || false,
            panCardNumber: familyMember?.panCardNumber || "",
           
            legalName: familyMember?.legalName || "",
            addressProof:  "",
            panCard: familyMember?.documents?.find(doc => doc.docName === "Pan Card")?.docUrl 
            ? { name: familyMember?.documents?.find(doc => doc.docName === "Pan Card").docUrl } 
            : null,
            addressProofFront: null,
            addressProofBack: null,
            userAddress: {
                flatNo: familyMember?.userAddress?.flatNo || "",
                area: familyMember?.userAddress?.area || "",
                landmark: familyMember?.userAddress?.landmark || "",
                town: familyMember?.userAddress?.town || "",
                state: familyMember?.userAddress?.state || "",
                pincode: familyMember?.userAddress?.pincode || ""
            }
        },
        validationSchema: validation,
        onSubmit: (data) => {            
            const editedFields = Object.keys(data).reduce((acc, key) => {
                if (
                  (key === "panCard" && data[key]?.name !== formik.initialValues[key]?.name) ||
                
                  (key !== "panCard" && data[key] !== formik.initialValues[key])
                ) {
                  acc[key] = data[key];
                }
                return acc;
              }, {});
              if(familyMember?._id){
                  UpdateKyc(editedFields);
            }
            else{
                submitKyc(editedFields);
                
              }
        },
    });


    
    useEffect(() => {
        if (familyMember && Object.keys(familyMember).length > 0) {
          setPanSelectedImage(url + familyMember?.documents?.find(doc => doc.docName === "Pan Card")?.docUrl);
        }
      }, [familyMember]);

    const submitKyc = async (data) => {
        
        const formData = new FormData();
        Object.keys((data)).map((key) => formData.append(key, data[key]));
        formData.delete("addressProof");
        formData.append("addressProof", data.addressProof.value);
        formData.delete("userAddress");
        formData.append("userAddress", JSON.stringify(data.userAddress));
        formData.append("familyMemberId", familymemberId);
        formData.append("isPanCard", panAvailabel)
        setOpenProgressBar(true)
        setSubmitting(true);
        const res = await familymemberServices.familymemberManualKyc(formData);
        setOpenProgressBar(false);
        setSubmitting(false);
        if (res && res.success) {
            enqueueSnackbar("KYC details submitted", { variant: "success" });
            setAnimating(true)
            setUser(res.data)
            handleClose();
            getallFamilyMember()

        } else {
            enqueueSnackbar(res.data?.message || "Enter Correct Details", { variant: "error" });
            setAnimating(false);
            setOpenProgressBar(false);
        }
        handleClose();
    }


    const UpdateKyc = async (data) => {
        console.log("updated ");
        
        const formData = new FormData();
        Object.keys((data)).map((key) => formData.append(key, data[key]));
        formData.delete("addressProof");
        formData.append("addressProof", data.addressProof.value);
        formData.delete("userAddress");
        formData.append("userAddress", JSON.stringify(data.userAddress));
        formData.append("isPanCard", panAvailabel)
        setOpenProgressBar(true)
        setSubmitting(true);
        const res = await familymemberServices.updateFamilyMemberKyc(familymemberId,formData);
        setOpenProgressBar(false);
        setSubmitting(false);
        if (res && res.success) {
            enqueueSnackbar("KYC details updated", { variant: "success" });
            setAnimating(true)
            setUser(res.data)
            handleClose();
            getallFamilyMember()
        } else {
            enqueueSnackbar(res.data?.message || "Something went wrong! Try again.", { variant: "error" });
            setAnimating(false);
            setOpenProgressBar(false);
    }
}

    const { errors, touched, handleSubmit, getFieldProps, values, setFieldValue, setFieldError, handleBlur, isSubmitting, setSubmitting } = formik;



    useEffect(() => {
        if (values.addressProof?.back) {

            let temp = validation.shape({
                addressProofBack: Yup.mixed().nullable().required("Please upload Address proof document.").test("FILE_SIZE", "Upload a file below 5MB.", value => value && value.size <= VALID_FILE_SIZE)
                    .test("FILE_FORMAT", "Uploaded file has unsupported format.", value => value && SUPPORTED_FORMATS.includes(value.type)),
            })
            setValidation(temp);
        }
        else {
            setValidation(manualKycSchema);
        }
    }, [values.addressProof])



    const handleImageChange = (file, type) => {
        if (file) {
            // Assuming that "file" is a File object
            const reader = new FileReader();

            reader.onload = (e) => {                
                // Set the selected image to the uploaded image
                if (type === "frontImage") {
                    setFrontSelectedImage(e.target.result);
                } else if (type === "backImage") {
                    setBackSelectedImage(e.target.result);
                } else if (type === "panImage") {
                    setPanSelectedImage(e.target.result);
                }
            };

            reader.readAsDataURL(file);
        } else {
            // If no file is selected, clear the selected image
            // setFrontSelectedImage(null);
        }
    };


    return (
        <Box mt={4}>
            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <Stack direction={"row"} justifyContent={"space-between"}>
                        <Typography variant="h4">Upload Your Documents</Typography>
                        {(userType !== "NRI" && userType !== "FOREIGNER") && (
                            <Button
                                variant="outlined"
                                style={{ marginTop: 3 }}
                                onClick={() => setIsManual(false)}
                            >
                                Switch Back to Instant KYC
                            </Button>
                        )}


                        {/* {(useType !== "NRI" || useType !== "FOREIGNER") && (<Button variant="outlined" style={{mt: 3}} onClick={() => setIsManual(false)}>Switch Back to Instant KYC</Button>)} */}
                    </Stack>
                    <Stack spacing={1.5} mt={3}>
                        <FormControl>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={panAvailabel}
                                        onChange={(e) => {
                                            const isChecked = e.target.checked;

                                            setPanAvailabel(isChecked); // ✅ Update state
                                            setFieldValue("panAvailabel", isChecked); // ✅ Ensure Formik knows

                                            if (!isChecked) {
                                                setFieldValue("panCard", null); // ✅ Reset file input
                                                setFieldValue("panCardNumber", ""); // ✅ Clear PAN number
                                                setPanSelectedImage(null); // ✅ Reset any selected image
                                            }
                                        }}
                                    />
                                }
                                label="Do you have a PAN Card?
                                        "
                            />
                        </FormControl>
                        <Stack
                            direction={{ md: "row", xs: "column" }}
                            spacing={{ md: 3, xs: 1.5 }}
                        >
                            {panAvailabel && (
                                <>
                                    <Grid item md={6}>

                                        <FormFileField
                                            label={"Upload PAN Card *"}
                                            inputProps={{
                                                error: Boolean(touched.panCard && errors.panCard),
                                                helperText: touched.panCard && errors.panCard,
                                                value: values.panCard,
                                                onChange: (file) => {
                                                    setFieldValue("panCard", file)
                                                    handleImageChange(file, "panImage");
                                                },
                                            }}
                                        />

                                        {panSelectedImage && (
                                            <div
                                                style={{
                                                    width: "auto",
                                                    height: "130px",
                                                    border: "1px solid rgb(183 183 183)",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    borderRadius: "6px",
                                                    marginTop: "8px",
                                                }}
                                            >
                                                <img
                                                    src={panSelectedImage}
                                                    alt="Selected Image"
                                                    style={{
                                                        maxWidth: "100%",
                                                        maxHeight: "100%",
                                                        objectFit: "cover",
                                                    }}
                                                />
                                            </div>
                                        )}
                                    </Grid>



                                    <FormField
                                        label={"Enter PAN Card No. *"}
                                        inputProps={{
                                            placeholder: "",
                                            ...getFieldProps("panCardNumber"),
                                            error: Boolean(touched.panCardNumber && errors.panCardNumber),
                                            helperText: touched.panCardNumber && errors.panCardNumber,
                                        }}
                                    />
                                </>
                            )}
                        </Stack>
                        <Stack direction={{ md: "row", xs: "column" }} spacing={{ md: 3, xs: 1.5 }}>
                            <FormField
                                label={"Legal Name*"}
                                inputProps={{
                                    placeholder: "legal name",
                                    ...getFieldProps("legalName"),
                                    error: Boolean(touched.legalName && errors.legalName),
                                    helperText: touched.legalName && errors.legalName,
                                }}
                            />
                        </Stack>
                        <Stack
                            direction={{ md: "row", xs: "column" }}
                            spacing={{ md: 3, xs: 1.5 }}
                        >
                            <FormAutoCompleted
                                label={"Address Proof Document *"}
                                options={addressProofOptions}
                                props={{
                                    value: values.addressProof,
                                    onChange: (e, value) => {
                                        setFieldValue("addressProof", value);
                                    },
                                    options: addressProofOptions,
                                    error: Boolean(touched.addressProof && errors.addressProof),
                                    helperText: touched.addressProof && errors.addressProof,
                                }}
                            ></FormAutoCompleted>

                            {/* {(userType === "NRI" || userType === "FOREIGNER") && <FormField
                                label={"Enter Passport No. *"}
                                inputProps={{
                                    placeholder: "",
                                    ...getFieldProps("passPortNumber"),
                                    error: Boolean(
                                        touched.passPortNumber && errors.passPortNumber
                                    ),
                                    helperText: touched.passPortNumber && errors.passPortNumber,
                                }}
                            />} */}
                        </Stack>

                        <Stack
                            direction={{ md: "row", xs: "column" }}
                            spacing={{ md: 3, xs: 1.5 }}
                        >
                            <Grid item md={6}>
                                <FormFileField
                                    label={"Upload Front Side *"}
                                    inputProps={{
                                        error: Boolean(
                                            touched.addressProofFront && errors.addressProofFront
                                        ),
                                        helperText:
                                            touched.addressProofFront && errors.addressProofFront,
                                        value: values.addressProofFront,
                                        onChange: (file) => {
                                            setFieldValue("addressProofFront", file);
                                            handleImageChange(file, "frontImage");
                                        },
                                    }}
                                />
                                {/* Display the selected image in a square container */}

                                {frontSelectedImage && (
                                    <div
                                        style={{
                                            width: "auto",
                                            height: "130px",
                                            border: "1px solid rgb(183 183 183)",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            borderRadius: "6px",
                                            marginTop: "8px",
                                        }}
                                    >
                                        <img
                                            src={frontSelectedImage}
                                            alt="Selected Image"
                                            style={{
                                                maxWidth: "100%",
                                                maxHeight: "100%",
                                                objectFit: "cover",
                                            }}
                                        />
                                    </div>
                                )}
                            </Grid>

                            <Grid item md={6}>
                                {values.addressProof?.back &&
                                    <FormFileField
                                        label={"Upload Back Side *"}
                                        inputProps={{
                                            error: Boolean(
                                                touched.addressProofBack && errors.addressProofBack
                                            ),
                                            helperText:
                                                touched.addressProofBack && errors.addressProofBack,
                                            value: values.addressProofBack,
                                            onChange: (file) => {
                                                setFieldValue("addressProofBack", file);
                                                handleImageChange(file, "backImage");
                                            },
                                        }}
                                    />}

                                {backSelectedImage && values.addressProof?.back &&
                                    <div
                                        style={{
                                            width: "auto",
                                            height: "130px",
                                            border: "1px solid rgb(183 183 183)",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            borderRadius: "6px",
                                            marginTop: "8px",
                                        }}
                                    >
                                        <img
                                            src={backSelectedImage}
                                            alt="Selected Image"
                                            style={{
                                                maxWidth: "100%",
                                                maxHeight: "100%",
                                                objectFit: "cover",
                                            }}
                                        />
                                    </div>
                                }

                            </Grid>
                        </Stack>
                    </Stack>
                    {/* <Typography mt={5} variant="h4">
                        Enter Bank Account Information
                    </Typography>
                    {userType === "NRI" &&
                        <Typography mt={1} variant="h5">
                            NRO Account Details (Mandatory)
                        </Typography>}
                    <Stack spacing={1.5} mt={3}>
                        <FormField
                            label={"Account Holder Name *"}
                            inputProps={{
                                ...getFieldProps("bankInformation.accHolderName"),
                                error: Boolean(
                                    touched.bankInformation?.accHolderName &&
                                    errors.bankInformation?.accHolderName
                                ),
                                helperText:
                                    touched.bankInformation?.accHolderName &&
                                    errors.bankInformation?.accHolderName,
                            }}
                        />
                        <Stack
                            direction={{ md: "row", xs: "column" }}
                            spacing={{ md: 3, xs: 1.5 }}
                        >
                            <FormField
                                label={"IFSC CODE *"}
                                inputProps={{
                                    ...getFieldProps("bankInformation.ifsc"),
                                    onBlur: (e) => {
                                        handleBlur(e);
                                        getBankFromIFSC();
                                    },
                                    error: Boolean(
                                        touched.bankInformation?.ifsc &&
                                        errors.bankInformation?.ifsc
                                    ),
                                    helperText:
                                        (touched.bankInformation?.ifsc &&
                                            errors.bankInformation?.ifsc) ||
                                        showBranch,
                                }}
                            />
                            <FormField
                                label={"Account Number *"}
                                inputProps={{
                                    ...getFieldProps("bankInformation.accNumber"),
                                    onChange: (e) => {
                                        // console.log(RegExp('^[0-9]*$').test(e.target.value));
                                        if (RegExp("^[0-9]*$").test(e.target.value)) {
                                            setFieldValue(
                                                "bankInformation.accNumber",
                                                e.target.value
                                            );
                                        }
                                    },
                                    error: Boolean(
                                        touched.bankInformation?.accNumber &&
                                        errors.bankInformation?.accNumber
                                    ),
                                    helperText:
                                        touched.bankInformation?.accNumber &&
                                        errors.bankInformation?.accNumber,
                                }}
                            />
                        </Stack>
                        <Stack
                            direction={{ md: "row", xs: "column" }}
                            spacing={{ md: 3, xs: 1.5 }}
                        >
                            <FormSelect
                                label={"Account Type *"}
                                options={accTypeOptions}
                                props={{
                                    ...getFieldProps("bankInformation.accType"),
                                    error: Boolean(
                                        touched.bankInformation?.accType &&
                                        errors.bankInformation?.accType
                                    ),
                                    helperText:
                                        touched.bankInformation?.accType &&
                                        errors.bankInformation?.accType,
                                }}
                            />
                            <FormFileField
                                label={"Cancelled Cheque Image*"}
                                inputProps={{
                                    error: Boolean(touched.bankCheque && errors.bankCheque),
                                    helperText: touched.bankCheque && errors.bankCheque,
                                    value: values.bankCheque,
                                    onChange: (file) => setFieldValue("bankCheque", file),
                                }}
                            />
                        </Stack>
                    </Stack> */}

                    <Typography mt={5} variant="h4">
                        Enter Address Information (As per ID Proof)
                    </Typography>
                    <Stack
                        direction={{ md: "row", xs: "column" }}
                        spacing={{ md: 3, xs: 1.5 }}
                    >
                        <FormField
                            label={"Flat No,House No *"}
                            inputProps={{
                                ...getFieldProps("userAddress.flatNo"),
                                error: Boolean(
                                    touched.userAddress?.flatNo &&
                                    errors.userAddress?.flatNo
                                ),
                                helperText:
                                    touched.userAddress?.flatNo &&
                                    errors.userAddress?.flatNo,
                            }}
                        />
                        <FormField
                            label={"Area,Street *"}
                            inputProps={{
                                ...getFieldProps("userAddress.area"),
                                error: Boolean(
                                    touched.userAddress?.area &&
                                    errors.userAddress?.area
                                ),
                                helperText:
                                    touched.userAddress?.area &&
                                    errors.userAddress?.area,
                            }}
                        />
                    </Stack>
                    <Stack
                        direction={{ md: "row", xs: "column" }}
                        spacing={{ md: 3, xs: 1.5 }}
                    >
                        <FormField
                            label={"Landmark"}
                            inputProps={{
                                ...getFieldProps("userAddress.landmark"),
                                error: Boolean(
                                    touched.userAddress?.landmark &&
                                    errors.userAddress?.landmark
                                ),
                                helperText:
                                    touched.userAddress?.landmark &&
                                    errors.userAddress?.landmark,
                            }}
                        />
                        <FormField
                            label={"Town/City *"}
                            inputProps={{
                                ...getFieldProps("userAddress.town"),
                                error: Boolean(
                                    touched.userAddress?.town &&
                                    errors.userAddress?.town
                                ),
                                helperText:
                                    touched.userAddress?.town &&
                                    errors.userAddress?.town,
                            }}
                        />
                    </Stack>
                    <Stack
                        direction={{ md: "row", xs: "column" }}
                        spacing={{ md: 3, xs: 1.5 }}
                    >
                        <FormAutoCompleted
                            label={"State *"}
                            options={stateOptions}
                            props={{
                                value: values.userAddress.state,
                                onChange: (e, value) => {
                                    setFieldValue("userAddress.state", value);
                                },
                                options: stateOptions,
                                error: Boolean(touched.userAddress?.state && errors.userAddress?.state),
                                helperText: touched.userAddress?.state && errors.userAddress?.state,
                            }}
                        ></FormAutoCompleted> 
                        <FormField
                            label={"Pincode *"}
                            inputProps={{
                                ...getFieldProps("userAddress.pincode"),
                                error: Boolean(
                                    touched.userAddress?.pincode &&
                                    errors.userAddress?.pincode
                                ),
                                helperText:
                                    touched.userAddress?.pincode &&
                                    errors.userAddress?.pincode,
                            }}
                        />
                    </Stack>

                    <LoadingButton
                        loading={isSubmitting}
                        type="submit"
                        variant="contained"
                        sx={{ mt: 5, width: { md: "auto", xs: "100%" } }}
                    >
                        Submit
                    </LoadingButton>
                </Form>
            </FormikProvider>
        </Box>
    );
}