import React, { useEffect, useState } from 'react'
import ModalComponent from './modalComponent'
import { Box, Button, Card, Dialog, Stack, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material'
import {  useNavigate } from 'react-router-dom';
import "./modals.css";
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';

export default function HufHeadAndTrust ({open,handleClose,hufAndTurst}) {
    
    const [answer,setAnswer]=useState('');
    const [showMsg,setShowMsg]=useState(false)
    const navigate = useNavigate();

    const handleTransitionExited = () => {
        setShowMsg(false);
    };

  
    const handlePopAction = (value)=>{
        if(value === "YES"){
            if(hufAndTurst){
            navigate('/huf-registration',{ state: {
                mode: 'register', 
                hufData: {},
              },})
            }else{
                navigate('/trust-registration',{ state: {
                    mode: 'register', 
                    trustData: {},
                  },}) 
            }
        }
        else{
            setShowMsg(true);
        }
    }



   
  return (
      <ModalComponent open={open} handleClose={handleClose} TransitionProps={{ onExited: handleTransitionExited }}>       
              <Box sx={{ textAlign: 'center', bgcolor: '#fdedec', borderRadius: 1, p:{md:3,xs:1} }}>
                  {!showMsg && 
                    <Box>                  
                    <Stack direction={'column'} rowGap={{md:4,xs:2}} alignItems={'center'} justifyContent={'center'}
                      sx={{mx:{xs:0,md:2}, px:{md:1}, py:{xs:3}}}>
                        {hufAndTurst?
                      <Typography variant='headerMenu' sx={{fontWeight:600}}>Are you the head of an HUF ?</Typography>:
                      <Typography variant='headerMenu' sx={{fontWeight:600}}>Are you the representative of the Trust ?</Typography>
                      }                   
                      <Box>
                      <Button variant='contained' sx={{boxShadow:1,fontWeight:700}} onClick={()=>handlePopAction('YES')}>YES</Button>
                      <Button sx={{ml:3,bgcolor:'#d1d5d6',color:'#252525',boxShadow:1,fontWeight:700,'&:hover':{bgcolor:'#acafb0'}}}   onClick={()=>handlePopAction('NO')}>NO</Button>
                      </Box>
                    </Stack>
                   </Box>}
                  {showMsg && <Stack sx={{ textAlign: 'center',alignItems:'center'}}>
                  {hufAndTurst?
                      <Typography variant='popMsg' py={3}>Sorry,You cannot create an HUF account <br/> as you are not the head of an HUF</Typography>:
                      <Typography variant='popMsg' py={3}>Sorry,You cannot create Trust account <br/> as you are not the representative of an Trust</Typography>
                      }
                      <Button sx={{ mt: 3,width:'fit-content'}} variant='contained' size='small' onClick={()=>{handleClose()}}>OK</Button>
                  </Stack>}
              </Box>
         
      </ModalComponent> 
  )
}
