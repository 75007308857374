import { Box, Checkbox, FormControlLabel, Radio, Button, Card, CardContent, InputAdornment, MenuItem, Select, Stack, TextField, Typography, Link, Divider, ToggleButtonGroup, ToggleButton, FormLabel } from "@mui/material";
import React, { createContext, useContext, useEffect, useRef, useState } from "react";
import {
    Link as RouterLink,
    useParams,
} from 'react-router-dom';
import CustomIcon from "../../../components/customIcon";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormCheckBox from "../../../components/form/formCheckBox";
import CompletePaymentModal from "../../../components/modals/completePayment";
import { useSnackbar } from "notistack";
import { DividerComponent, HtmlTooltip } from "../../../components/miniComponents";
import CustomBreadcrumb from "../../../components/customBreadcrumb";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { checkLogin, convertMsToTime, convertToIndianFormat, getTitle } from './../../../utils/functions';
import transactionServices from "../../../services/transactionServices";
import couponServices from "../../../services/couponServices";
import moment from "moment";
import ConfirmaTransaction from "../../../components/modals/confirmTransaction";
import RejectTransaction from "../../../components/modals/RejectTransaction.js";
import AreYouSureModal from "../../../components/modals/areYouSureModal.js"
import PaymentMode from "../../../components/modals/paymentMode";
import distributionServices from "../../../services/distributionServices";
import authServices from "../../../services/authServices.js";
import userServices from "../../../services/userServices.js";
import familyMemberServices from "../../../services/familymemberServices.js";
import { convertFieldResponseIntoMuiTextFieldProps } from "@mui/x-date-pickers/internals";
import trustServices from "../../../services/trustServise.js";

const InvestContext = createContext();

export default function InvestPage() {
    const [activeStep, setActiveStep] = useState(0);
    const [selectedMode, setMode] = useState(0);
    const [amount, setAmount] = useState("");
    const [amountAfterDiscount, setAmountAfterDiscount] = useState(0);
    const [coupon, setCoupon] = useState("");
    const [couponCashback, setCouponCashback] = useState(0);
    const [referedBy, setReferedBy] = useState("");
    const [highValueCashback, setHighValueCashback] = useState(0);
    const [commitmentDate, setCommitmentDate] = useState()
    const [beneficiaryInfo, setBeneficiaryInfo] = useState({
        "name": "",
        "relation": ""
    });
    const [termsAccepted, setAccepted] = useState(false);
    const [editable, setEditable] = useState(true);
    const [editableCoupon, setEditableCoupon] = useState(true);

    const [listing, setListing] = useState();
    const [url, setUrl] = useState("");
    const [costFrax, setCostFrax] = useState(0);
    const [totalCost, setTotalCost] = useState(0);
    const [maxAmount, setMaxAmount] = useState(0);
    const [minAmount, setMinAmount] = useState(0);
    const [amtTobePaid, setAmttoBePaid] = useState(0)
    const [pendingAmt, setPendingAmt] = useState(0);
    const [isFullPayment, setIsFullPayment] = useState(null);
    const [isFullPaymentEditable, setIsFullPaymentEditable] = useState(true);
    const [minCoOwnAmount, setCoOwnMinAmount] = useState(0);
    const [minFrax, setMinFrax] = useState();
    const [selectDiscount, setSelectDiscount] = useState(0);
    const [NoFrax, setNoFrax] = useState(0);
    const [dealerMinAmtRCO, setDealerMinAmtRCO] = useState(0);
    const [dealerMinAmtTokenization, setDealerMinAmtTokenization] = useState(0);
    const [dealRateperSF, setdealerRateperSF] = useState(0);
    const [discPercent, setDiscPercent] = useState(0);
    const [discRPF, setDiscRPF] = useState(0);
    const [fraxWithoutDisc, setFraxWtihoutDisc] = useState(0)
    const [amtPaid, setAmtPaid] = useState(0)

    const [transaction, setTransaction] = useState();
    const [remainingFrax, setRemainingFrax] = useState();

    const { enqueueSnackbar } = useSnackbar();
    const { state } = useLocation();
    // const user = useSelector(state => state.user.data);
    const user = JSON.parse(sessionStorage.getItem("reduxState"))?.user?.data;

    // const user = JSON.parse(sessionStorage.getItem("reduxState"))?.user?.data;
    const [availableBlockchainAccounts, setAvailableBlockchainAccounts] = useState([]);
    const [selectedBlockchainAccountIndex, setSelectedBlockchainAccountIndex] = useState(0);
    const [selectedBlockChainAccount, setSelectedBloackChainAccount] = useState("");
    const [chosenDltAccountAfterCommitment, setChosenDltAccountAfterCommitment] = useState()
    const [changeBlockchainAccountModalOpen, setChangeBlockchainAccountModalOpen] = useState(false);
    const [investmentAccountModalOpen, setInvestmentAccountModalOpen] = useState(false);
    const [selectedInvestmentAccountIndex, setSelectedInvestmentAccountIndex] = useState(0);
    const [accountSelected, setAccountSelected] = useState("Personal");
    const [chosenAccountAfterCommitment, setChosenAccountAfterCommitment] = useState(false)
    const [crossedAmount, setCrossedAmount] = useState(0);
    const [disablecheckbox, setDisablecheckbox] = useState(false);
    const [familyMembers, setFamilyMembers] = useState([]);
    const [entityId, setEntityId] = useState(null);
    const [trust,setTrust]=useState()
    const { propertyId } = useParams();
    const navigate = useNavigate();


    useEffect(() => {
        if (!checkLogin()) {
            navigate(`/dashboard/property/${propertyId}`, { replace: true });
        }
    }, [propertyId, navigate]);
    

    useEffect(() => {
        
        if (accountSelected === "HUF") {
            fetchDltWallet(user.hufDetails[0]._id);
        } else if (accountSelected === "Personal") {
            fetchDltWallet(user._id);
        }
         else if (accountSelected === "Trust") {
            fetchDltWallet(trust[0]?.entityId);

            // fetchTrustDltWallet();
        } else if (accountSelected === familyMembers?.find(member => member.username === accountSelected)?.username) {
            fetchDltWallet(familyMembers?.find(member => member.username === accountSelected)?.familyMemberId);
        }
    }, [accountSelected])

    const fetchDltWallet = async (id) => {
        if (id) {

            const dltWallet = await userServices.getDltWallet(id);
            if (dltWallet && dltWallet.success) {
                setAvailableBlockchainAccounts(dltWallet.data)
            }
        }
    }




    useEffect(() => {
        if (discRPF || costFrax) {
            calculateMinAmm();
        }
        // eslint-disable-next-line
    }, [discRPF, costFrax, selectedMode, transaction])

    useEffect(() => {
        validate();
        // eslint-disable-next-line
    }, [selectedMode, amount, beneficiaryInfo, termsAccepted, editable, isFullPayment]);

    useEffect(() => {
        if (!transaction) {
            setAmount("")
            setCoupon("")
            setCouponCashback(0)
            setHighValueCashback(0)
            setEditable(true);
        }
        // eslint-disable-next-line
    }, [selectedMode])

    useEffect(() => {
        setCouponCashback(0);
    }, [coupon])

    useEffect(() => {
        validateCoupon();
        setHighValueCashback(0);
    }, [amount])

    const validate = () => {
        if (selectedMode > -1 && activeStep < 1) setActiveStep(1);
        if (!editable && activeStep < 2) setActiveStep(2);
        if (beneficiaryInfo.name && beneficiaryInfo.relation && activeStep < 3) setActiveStep(3);
        if (termsAccepted) setActiveStep(4);
        if (selectedMode > -1 && !editable && beneficiaryInfo.name && beneficiaryInfo.relation && termsAccepted && isFullPayment !== null) { setActiveStep(5); }
        else if (activeStep === 5 && editable) setActiveStep(4);
        else if (activeStep === 5 && !termsAccepted) setActiveStep(4);
    }

    useEffect(() => {
        getPrevInvestments();
        return () => setTransaction(null);
        // eslint-disable-next-line
    }, [state, familyMembers])

    useEffect(() => {
        if (transaction) {
            
            setMode(transaction.rightToOwnerShip ? 1 : 0);
            setAmount(transaction.amount);
            setSelectDiscount(transaction?.discounts?.select || 0);
            setAmountAfterDiscount(transaction.amount - (transaction?.discounts?.select || 0))
            setAmttoBePaid(transaction.amtTobePaid);
            setPendingAmt(transaction.pendingAmt);
            setBeneficiaryInfo(transaction.beneficiaryInfo);
            setNoFrax(transaction.noOfFracs)
            setEditable(false);
            setIsFullPaymentEditable(false);
            setAmtPaid(transaction.amtPaid || 0)
            setCrossedAmount(transaction.amount || 0);
        }
    }, [transaction])

    const getDynamicAmount = async () => {
        const res = await distributionServices.getDynamicAmount({
            mobileNumber: user.mobileNumber,
            listingId: state?.id
        });
        if (res && res.success) return res.data;
    }

    useEffect(() => {
        if (totalCost && costFrax) {
            // let frax = Math.ceil((totalCost / 100) / costFrax);
            // setCoOwnMinAmount(Math.ceil(frax * costFrax))
            let frax = Math.ceil((dealerMinAmtRCO || 200000) / (discRPF || costFrax));
            setCoOwnMinAmount(Math.ceil(frax * (discRPF || costFrax)))
        }
    }, [totalCost, costFrax])

    const getPrevInvestments = async () => {
        let data = {
            "listingId": state?.id,
            "userId": user._id
        }
        const res = await transactionServices.getPrevInvestments(data);
        if (res && res.success && res.listing[0]) {
            setTransaction(res.data[0])
            let propertyData = res.listing[0];
            let transactionData = res.data[0];
            setAccountSelected(res.data[0]?.entityType === "HUF" ? "HUF" : res.data[0]?.entityType === "FAMILYMEMBER" ? familyMembers.find(member => member?.familyMemberId === res.data[0]?.entityId)?.username : res.data[0]?.entityType === "TRUST" ? "Trust" : "Personal")
            setChosenAccountAfterCommitment(res.data[0]?.payments.length > 0)
            setSelectedBloackChainAccount(res.data[0]?.dltAccount || " ")
            setChosenDltAccountAfterCommitment(res.data[0]?.dltAccount)
            const dynamicPrice = await getDynamicAmount();
            setCommitmentDate(res.data[0]?.created_at)
            setListing(propertyData)

            setUrl(res.url)
            setTotalCost(Number(calculateTotalRetailCost(propertyData)));

            const fraxValue = (Number(calculateTotalRetailCost(propertyData))) / (144 * propertyData?.characteristics?.builtupArea);
            setCostFrax(fraxValue);
            setRemainingFrax(propertyData.remainingFracs);

            const remainingAmount = propertyData.remainingFracs * fraxValue;

            const maxInvAmount = Math.ceil(propertyData.remainingFracs * fraxValue);
            if (selectedMode === 0) {
                const minInvAmount = propertyData?.transactionDetails?.minInvestmentAmount;
                const amount = remainingAmount <= minInvAmount ? maxInvAmount : minInvAmount.toFixed(0);
                setMinAmount(amount);
            } else if (selectedMode === 0) {
                const minInvRcoAmount = propertyData?.transactionDetails?.minInvAmtOwnership;
                const amount = remainingAmount <= minInvRcoAmount ? minInvRcoAmount : minInvRcoAmount.toFixed(0);
                setMinAmount(amount)
            }



            setDealerMinAmtTokenization(amount);
            setDealerMinAmtRCO(propertyData?.transactionDetails?.minInvAmtOwnership);

            if (dynamicPrice?.dealFinancials?.listingPrice) {
                setDiscRPF((Number(calculateTotalCost(propertyData, dynamicPrice?.dealFinancials?.listingPrice))) / (144 * propertyData?.characteristics?.builtupArea))
                setdealerRateperSF(dynamicPrice?.dealFinancials?.listingPrice)
                setDealerMinAmtTokenization(dynamicPrice?.dealFinancials?.minInvestmentAmountTokenization);
                setDealerMinAmtRCO(dynamicPrice?.dealFinancials?.minInvestmentAmountRCO);
            }
            if (transactionData) {
                setFraxWtihoutDisc(Math.trunc(transactionData?.amount / fraxValue));
            }
            setCostFrax(fraxValue);
        }
    }

    const calculateTotalRetailCost = (propertyData) => {
        const currentTotalCost = propertyData?.transactionDetails?.totalCostOfPurchase;
        const currentSalePrice = propertyData?.transactionDetails?.salePrice;
        const retailSalePrice = propertyData?.transactionDetails?.retailBasePricePSF * propertyData?.characteristics?.builtupArea;
        if (propertyData?.status == "LIVE") return currentTotalCost - currentSalePrice + retailSalePrice - propertyData?.transactionDetails?.gstINR + (retailSalePrice * propertyData?.transactionDetails?.gst / 100) - propertyData?.transactionDetails?.stampDutyINR + (retailSalePrice * propertyData?.transactionDetails?.stampDuty / 100);
        else return currentTotalCost - currentSalePrice + retailSalePrice;
    }

    const calculateTotalCost = (propertyData, leadSalePricePSF) => {
        if (leadSalePricePSF == 0) return calculateTotalRetailCost(propertyData);
        const currentTotalCost = propertyData?.transactionDetails?.totalCostOfPurchase;
        const currentSalePrice = propertyData?.transactionDetails?.salePrice;
        const retailSalePrice = propertyData?.transactionDetails?.retailBasePricePSF * propertyData?.characteristics?.builtupArea;
        const leadSalePrice = (leadSalePricePSF || 0) * propertyData?.characteristics?.builtupArea;
        if (propertyData?.status == "LIVE") return currentTotalCost - currentSalePrice + leadSalePrice - propertyData?.transactionDetails?.gstINR + (retailSalePrice * propertyData?.transactionDetails?.gst / 100) - propertyData?.transactionDetails?.stampDutyINR + (retailSalePrice * propertyData?.transactionDetails?.stampDuty / 100);
        else return currentTotalCost - currentSalePrice + leadSalePrice;
    }

    const calculateAmount = async () => {
        let frax;
        let actualfrax;
        setIsFullPaymentEditable(true);
        setIsFullPayment(null);

        if (editable) {
            frax = Math.ceil(amount / (discRPF || costFrax))
            actualfrax = Math.ceil(amount / costFrax)
            if (discRPF) {
                const amountWithdiscRPF = Math.ceil(frax * (discRPF || costFrax))
                actualfrax = Math.floor(amountWithdiscRPF / costFrax)
            }
            // Check frax floor to compare amount entered by customer is fixed or not
            const fraxFloor = frax - 1;
            const actualFraxFloor = actualfrax - 1;
            const amountWithFraxFloor = Math.ceil(fraxFloor * (discRPF || costFrax))
            const amountWithActualFraxFloor = Math.ceil(actualFraxFloor * (costFrax))
            if (amountWithFraxFloor == amount) {
                frax = fraxFloor;
                actualfrax = Math.floor(amountWithFraxFloor / costFrax);
            }
            if (!discRPF && amountWithActualFraxFloor == amount) {
                actualfrax = actualFraxFloor;
            }
            checkAvailableFrax(frax, actualfrax);
        }
        else {
            setEditable(true);
        }
        if (editable) {
            const roundedUpAmount = Math.ceil(frax * (discRPF || costFrax));
            if (roundedUpAmount > 200000) {
                setDisablecheckbox(false);
            }
            else {
                setIsFullPayment(true);
                setDisablecheckbox(true);
                setIsFullPaymentEditable(false)
            }
            setAmttoBePaid(roundedUpAmount)
            setPendingAmt(0);
        }
    }

    const fetchFamilyMembers = async () => {
        const res = await familyMemberServices.getFamilyMembers(user._id);
        if (res && res.success) {

            setFamilyMembers(res.data);
        }
    }
    const fetchTrust = async () => {
        const res = await trustServices.getTrustDetails(user._id);
        if (res && res.success) {
            setTrust(res.data);
        }
    } 

    useEffect(() => {
        fetchFamilyMembers();
        fetchTrust()
    }, [state])

    const validateCoupon = async () => {
        if (editableCoupon && coupon.length > 3) {
            const res = await couponServices.validateCoupon({ couponCode: coupon, listingId: listing._id });
            if (res && res.success) {
                if (res.data.isDistributor) {
                    setCouponCashback(Math.floor(amount * res.data.discount / 100));
                } else {
                    setCouponCashback(Math.floor(amount * 0.025));
                    setReferedBy(res.data.createdBy);
                }
                setEditableCoupon(false);
                enqueueSnackbar(res.message, { variant: "success" })
            } else {
                setCouponCashback(0);
                enqueueSnackbar(res.message, { variant: "error" })
            }
        } else setEditableCoupon(true);
    }

    const calculateMinAmm = () => {
        let frax = remainingFrax
        setMaxAmount(Math.ceil(frax * (discRPF || costFrax)))
        if (selectedMode === 0) {
            let frax = Math.ceil((dealerMinAmtTokenization || 50000) / (discRPF || costFrax));
            frax = frax < remainingFrax ? frax : remainingFrax;
            setMinFrax(frax);
            setMinAmount(Math.ceil(frax * (discRPF || costFrax)));
        }
        else if (selectedMode === 1) {
            let frax = Math.ceil((dealerMinAmtRCO || 200000) / (discRPF || costFrax));
            frax = frax < remainingFrax ? frax : remainingFrax
            setMinFrax(frax);
            setMinAmount(Math.ceil(frax * (discRPF || costFrax)))
        }
    }

    const checkAvailableFrax = async (frax, actualfrax) => {

        let data = {
            "listingId": state?.id,
            "user_id": user?._id,
            "noOfFracs": frax
        }
        const res = await transactionServices.checkFracs(data);
        if (res && res.success) {
            // setDisablecheckbox(false);
            setEditable(false);
            setNoFrax(frax);
            setFraxWtihoutDisc(actualfrax)
            const temp = Math.ceil(frax * (discRPF || costFrax));
            setAmount(temp);
            setAmountAfterDiscount(temp);
        }
        else {
            setDisablecheckbox(true)
            enqueueSnackbar(res.data.message, { variant: "error", autoHideDuration: 6000 })
            setNoFrax(0);
            setFraxWtihoutDisc(0);
            setAmttoBePaid(0);
            // setAmount("");
            setSelectDiscount(0);
            setAmountAfterDiscount(0);
            return;

        }

        if (user?.membershiptype === "SELECT" && state?.id != "661e6d8b6a19c9fb8a78a2e0" && state?.id != "678de00d125696225ad1acce") {
            const totalDiscount = Math.round(((frax * listing?.transactionDetails?.facilitationFees) / listing?.numberOfFracs) * 0.1);
            setSelectDiscount(totalDiscount ? (totalDiscount?.toFixed(0)) : 0);
            const amount = Math.ceil(frax * (discRPF || costFrax));
            setCrossedAmount(amount);
            const discountedAmount = amount - totalDiscount;
            setAmountAfterDiscount(discountedAmount);
            // const fraxWithNoDiscount=Math.ceil(discountedAmount/(discRPF || costFrax));
            // setFraxWtihoutDisc(fraxWithNoDiscount)
            setAmount(amount);
            if (discountedAmount > 200000) {
                setDisablecheckbox(false)
                setAmttoBePaid(discountedAmount);
            }
            else {
                setIsFullPayment(true);
                if (discountedAmount <= 200000) setDisablecheckbox(true); setIsFullPaymentEditable(false);
                setAmttoBePaid((discountedAmount))
                setPendingAmt(0)
            }
        }
    }

    return (
        <Box sx={{ mt: { md: 0, xs: 0 }, }}>
            <CustomBreadcrumb id={state?.id} />


            <InvestContext.Provider value={{ amtPaid, user, minCoOwnAmount, maxAmount, transaction, activeStep, setMode, selectedMode, listing, url, amount, amountAfterDiscount, setAmount, coupon, setCoupon, referedBy, beneficiaryInfo, setBeneficiaryInfo, termsAccepted, setAccepted, calculateAmount, validateCoupon, NoFrax, setNoFrax, fraxWithoutDisc, discRPF, costFrax, editable, setEditable, editableCoupon, setEditableCoupon, totalCost, minAmount, getPrevInvestments, selectDiscount, couponCashback, setCouponCashback, highValueCashback, setHighValueCashback, pendingAmt, setPendingAmt, setAmttoBePaid, amtTobePaid, isFullPayment, setIsFullPayment, isFullPaymentEditable, availableBlockchainAccounts, selectedBlockchainAccountIndex, setSelectedBlockchainAccountIndex, changeBlockchainAccountModalOpen, setChangeBlockchainAccountModalOpen, crossedAmount, setCrossedAmount, disablecheckbox, investmentAccountModalOpen, setInvestmentAccountModalOpen, selectedInvestmentAccountIndex, setSelectedInvestmentAccountIndex, accountSelected, setAccountSelected, chosenAccountAfterCommitment, selectedBlockChainAccount, setSelectedBloackChainAccount, chosenDltAccountAfterCommitment, commitmentDate, familyMembers,trust, entityId, setEntityId }}>
                <Stack direction={{ md: "row", xs: "column-reverse" }} spacing={{ md: 5, xs: 2 }}>
                    <InvestingSteps />
                    <RightSideContent />
                </Stack>
            </InvestContext.Provider>
        </Box>
    )
}

const InvestingSteps = () => {

    const { activeStep, transaction } = useContext(InvestContext);

    return (
        <Stack direction={"column"} flex={1} sx={{ width: { md: "calc(55% - 20px)", xs: "100%" } }} spacing={"6px"}>
            {transaction && <PropertyDetailCard />}
            {activeStep > 0 && <OwnershipCard />}
            {activeStep > 0 && <AmountCard />}
            {activeStep > 1 && <CouponCard />}
            {activeStep > 1 && <InvestmentAccounts />}
            {activeStep > 1 && <DLTAccountsCard />}
            {activeStep > 1 && <BenificiaryCard />}
            {activeStep > 2 && !transaction && <ConditionsCard />}
            <Box sx={{ display: { md: "none", xs: "block" }, pt: 3 }}>
                <PaymentInformation />
                <Box height={150}>

                </Box>
            </Box>

        </Stack>
    )
}

const RightSideContent = () => {
    const { transaction } = useContext(InvestContext);
    return (
        <Stack sx={{ width: { md: "calc(45% - 20px)", xs: "100%" }, height: "fit-content" }} spacing={{ md: !transaction ? 4 : 0, xs: 2 }}>
            {
                !transaction &&
                <PropertyDetailCard />
            }
            <Box sx={{ display: { md: "none", xs: "block" } }}>
                <Divider />
            </Box>
            <Box sx={{ display: { md: "block", xs: "none" } }}>
                <PaymentInformation />
            </Box>
        </Stack>
    )
}

const PropertyDetailCard = () => {
    const { listing, url } = useContext(InvestContext);
    const navigate = useNavigate();
    return (
        <Stack direction={"column"} spacing={2}>
            <Card sx={{ borderRadius: "10px" }}>
                <CardContent>
                    <Typography variant="body3" sx={{ fontWeight: 500 }}>Property Details</Typography>
                    <Divider sx={{
                        width: "calc(100% + 80px)",
                        margin: '15px -40px 24px'
                    }} />
                    <Stack direction={"row"} spacing={{ md: 4, xs: 2 }} >
                        <Box
                            sx={{
                                paddingLeft: { md: "25%", xs: 0 },
                                background: `url('${url}${listing?.listingImages[0]?.docUrl}')`,
                                backgroundSize: "cover",
                                borderRadius: "10px",
                                width: { xs: "25%", md: "auto" },
                                minWidth: { xs: "25%", md: "auto" },
                                paddingTop: { xs: "25%", md: 0 },
                                height: { xs: "fit-content", md: "auto" },
                                ml: { md: 0, xs: 1 },
                                mt: { md: 0, xs: 1 }
                            }}
                        >

                        </Box>
                        <Stack direction={"column"} paddingTop={{ md: 2, xs: 1 }} paddingBottom={{ md: 4, xs: 1 }} flex={1}>
                            <Stack>
                                <Typography variant="caption">{listing?.propertyType}</Typography>
                                <Stack direction={"row"} spacing={2}>
                                    <Typography variant="headerMenu" sx={{ fontWeight: 600 }}>{getTitle(listing)}&nbsp;&nbsp;-&nbsp;&nbsp;{listing?.project?.projectName}</Typography>
                                    {/* <Typography variant="headerMenu" sx={{fontWeight:600,whiteSpace:"nowrap",textOverflow:"ellipsis"}}>{listing?.project?.projectName}</Typography> */}
                                </Stack>
                                <Typography variant="caption">{listing?.developer?.username}</Typography>
                                <Stack direction={"row"} spacing={5} mt={2}>
                                    <Link onClick={() => navigate(`/dashboard/property/${listing._id}`, { state: { id: listing._id } })}>
                                        <Typography variant="body2" sx={{ fontWeight: 500, cursor: "pointer" }} >View Details</Typography>
                                    </Link>
                                    {/* <Link  >
                                    <Typography variant="body2" sx={{fontWeight:500,cursor:"pointer"}} >View Location</Typography>
                                </Link> */}
                                </Stack>
                            </Stack>
                        </Stack>
                    </Stack>
                </CardContent>
            </Card>
        </Stack>
    )
}


const PaymentInformation = () => {
    const [paymentModal, setPaymentModal] = useState(false);
    const [confirmModal, setConfirmModal] = useState(false);
    const [rejectModal, setRejectModal] = useState(false);
    const [paymentModeModal, setPaymentModeModal] = useState(false);
    const [reqData, setReqData] = useState();
    const [reqData2, setReqData2] = useState();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();


    const { amtPaid, coupon, couponCashback, activeStep, selectedMode, amount, amountAfterDiscount, pendingAmt, setPendingAmt, setAmttoBePaid, amtTobePaid, discRPF, costFrax, referedBy, beneficiaryInfo, NoFrax, listing, user, transaction, getPrevInvestments, discount, selectDiscount, isFullPayment, setIsFullPayment, isFullPaymentEditable, availableBlockchainAccounts, selectedBlockchainAccountIndex, setNoFrax, crossedAmount, setCrossedAmount, disablecheckbox, accountSelected, roundedUpAmount, commitmentDate, familyMembers,trust, entityId  } = useContext(InvestContext);

    useEffect(() => {
        if (listing && user) {
            let temp = {
                rightToOwnerShip: selectedMode === 1,
                amount: amount,
                rpf: discRPF || costFrax,
                pendingAmt: pendingAmt,
                amtTobePaid: amtTobePaid,
                beneficiaryInfo: beneficiaryInfo,
                noOfFracs: NoFrax,
                listingId: listing._id,
                projectId: listing.projectId,
                developerId: listing.developerId,
                user_id: user._id,
                accountSelected: accountSelected,
                entityType: accountSelected === "HUF" ? "HUF" : accountSelected === "Trust" ? "TRUST" : accountSelected === "Personal" ? "PERSONAL" : "FAMILYMEMBER",
                entityId: entityId,
                ...((accountSelected !== "Personal" && accountSelected !== "HUF" && accountSelected !== "Trust") && { username: familyMembers.find(member => member.username === accountSelected)?.username }),
                dltAccount: availableBlockchainAccounts[selectedBlockchainAccountIndex || 0]?.publicAddress,
                dltNickname: availableBlockchainAccounts[selectedBlockchainAccountIndex || 0]?.name,
                couponCashback,
                discounts: {
                    select: +selectDiscount
                },
                coupon,
                referedBy
            }

            if (transaction) {
                let temp2 = {
                    transactionId: transaction._id
                }
                setReqData2(temp2)
            }
            setReqData(temp);
        }
        // eslint-disable-next-line
    }, [selectedMode, amount, beneficiaryInfo, NoFrax, listing, selectDiscount, coupon, couponCashback, isFullPayment, selectedBlockchainAccountIndex, accountSelected, availableBlockchainAccounts]);

    const handleCompletePayment = () => {
        if (amtTobePaid <= 80000) {
            setPaymentModeModal(true);
        }
        else {
            setPaymentModal(true);
        }
    }

    const handleBankTransfer = () => {
        setPaymentModeModal(false);
        setPaymentModal(true);
    }

    const handleFullPayment = async (event, newValue) => {
        const isChecked = newValue;

        setIsFullPayment(isChecked);
        if (isChecked) {
            const temp = amtTobePaid + pendingAmt;
            setAmttoBePaid(temp);
            setPendingAmt(0);
        } else {
            const frax = amount / (discRPF || costFrax);
            let roundedUpAmount = Math.ceil(frax * (discRPF || costFrax));
            if (selectDiscount) {
                roundedUpAmount = roundedUpAmount - selectDiscount;
            }
            if (roundedUpAmount >= 205000) {
                // const amount = amtTobePaid;
                const oldAmountToBePaid = Math.round(200000 + (roundedUpAmount - 200000) / 10);
                setAmttoBePaid(oldAmountToBePaid);
                setPendingAmt(roundedUpAmount - oldAmountToBePaid);
            }
        }
    }

    const handlePayementGateway = async () => {
        setPaymentModeModal(false);
        const res = await transactionServices.createOrder({ ...reqData, paymentMethod: "RZ" });
        // console.log(res);
        if (res && res.success) {
            const order = res.data;
            const options = {
                key: order.notes.key,
                amount: order.amount,
                currency: "INR",
                name: user.name,
                description: "Purchase Frax",
                order_id: order.id,
                // callback_url: `https://pupperazy-backend.odinflux.com/transaction/verifyOrder/${order.notes.transactionId}`,
                // callback_url: `http://192.168.0.114:5000/transaction/verifyOrder/${order.notes.transactionId}`,
                // callback_url: `http://192.168.0.109:80/transaction/verifyOrder/${order.notes.transactionId}`,
                // callback_url: `${process.env.REACT_APP_API_URL}transaction/verifyOrder/${order.notes.transactionId}`,
                // callback_url: `https://api.pupperazy.com/transaction/verifyOrder/${order.notes.transactionId}`,
                handler: (res) => {
                    // console.log(res,"test");
                    enqueueSnackbar("Payment successfull.", { variant: "success", anchorOrigin: { horizontal: "center", vertical: "bottom" }, autoHideDuration: 2000 })
                    navigate("/transactions")
                },
                prefill: {
                    name: user.name,
                    email: user.email,
                    contact: user.number
                },
                notes: {
                    "address": "REALX"
                },
                theme: {
                    "color": "#2F6BF2"
                },
                modal: {
                    escape: false,
                    confirm_close: true,
                    ondismiss: () => {
                        // console.log("fkdhf")
                    }
                }
            };
            const razor = new window.Razorpay(options);
            razor.on('payment.failed', function (response) {
                // console.log(response.error.code);
                // console.log(response.error.description);
                // console.log(response.error.source);
                // console.log(response.error.step);
                // console.log(response.error.reason);
                // console.log(response.error.metadata.order_id);
                // console.log(response.error.metadata.payment_id);
            });
            razor.open();
        }
        else {
            enqueueSnackbar(res.data.message, { variant: "error", anchorOrigin: { horizontal: "center", vertical: "bottom" }, autoHideDuration: 2000 })
        }
    }

    return (
        <Stack direction={"column"} spacing={2}>
            {paymentModal && <CompletePaymentModal open={paymentModal} handleClose={() => setPaymentModal(false)} paymentDetails={<PaymentDetails inModal={true} />} isFullPayment={isFullPayment} data={reqData} getTransaction={getPrevInvestments} />}
            {/* below we do  amount={amtTobePaid || amount} as for first time when user is making vert first payment , we need to send whole amount but later we need to send amount he has to now pay or pending amount one and the same*/}
            {confirmModal && <ConfirmaTransaction open={confirmModal} handleClose={() => setConfirmModal(false)} amtPaid={amtPaid} data={reqData2} amount={amountAfterDiscount} pendingAmt={pendingAmt} amtTobePaid={amtTobePaid} commitmentDate={commitmentDate} />}
            <RejectTransaction open={rejectModal} handleClose={() => setRejectModal(false)} data={reqData2} amount={amount} pendingAmt={pendingAmt} amtTobePaid={amtTobePaid} />
            <PaymentMode open={paymentModeModal} handleClose={() => setPaymentModeModal(false)} onBankTransfer={handleBankTransfer} onPaymentGateway={handlePayementGateway} />
            <Stack direction={"column"} spacing={2} sx={{
                display: {
                    md: "block",
                    xs: activeStep > 2 ? "block" : "none",
                }
            }}>
                <Card sx={{ borderRadius: "10px" }}>
                    <CardContent>
                        <Typography variant="body3" sx={{ fontWeight: 500 }}>Payment Information</Typography>
                        <Divider sx={{
                            width: "calc(100% + 80px)",
                            margin: '15px -40px 24px'
                        }} />
                        <PaymentDetails />
                    </CardContent>
                </Card>
            </Stack>

            <Card>
                <CardContent>
                    <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} mt={2}>
                        {/* <FormLabel id="toggle-buttons-group-label"  sx={{ lineHeight: 1 }}> */}
                        <Typography variant="body3">Select payment option</Typography>
                        {/* </FormLabel> */}
                        <ToggleButtonGroup value={isFullPayment} exclusive onChange={handleFullPayment} disabled={disablecheckbox || !isFullPaymentEditable} sx={{ cursor: disablecheckbox === true ? "not-allowed" : "pointer" }}>
                            <ToggleButton value={true} aria-label="Full payment" sx={{ border: '1px solid black', padding: "8px 20px", bgcolor: isFullPayment === true ? "#ff382d !important" : "", color: isFullPayment === true ? "#FFF !important" : "gray" }}>
                                Full payment
                            </ToggleButton>
                            <ToggleButton value={false} aria-label="Partial payment" sx={{ cursor: disablecheckbox === true ? "not-allowed" : "pointer", border: '1px solid black', padding: "8px 20px", bgcolor: isFullPayment === false ? "#ff382d !important" : "", color: isFullPayment === false ? "#FFF !important" : "" }}>
                                Partial payment
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Stack>
                </CardContent>
            </Card>

            <Stack spacing={2} sx={{ display: { md: "block", xs: "none" } }}>
                {
                    transaction ?
                        <>
                            {
                                transaction?.status == "PARTPAID" &&
                                <Stack direction="row" alignItems="center" gap="10px">
                                    <Button disabled={transaction?.newpaymentMade} variant="contained" color="secondary" onClick={() => setConfirmModal(true)}
                                        sx={{ width: "100%" }}
                                    >
                                        Make Additional Payment
                                    </Button>
                                    {/* <Button sx={{ width: "50%",padding:'19px 24px !important'}} onClick={() => { setRejectModal(true); }} variant="outlined" >Cancel</Button> */}
                                </Stack>
                            }
                            {
                                transaction?.status == "CREATED" &&
                                <Stack direction="row" alignItems="center" gap="10px">
                                    <Button variant="contained" color="secondary" onClick={() => setConfirmModal(true)}
                                        sx={{ width: "50%" }}
                                    >
                                        Confirm
                                    </Button>
                                    <Button sx={{ width: "50%", padding: '19px 24px !important' }} onClick={() => { setRejectModal(true); }} variant="outlined" >Cancel</Button>
                                </Stack>
                            }
                        </>
                        :
                        <>
                            <Button fullWidth disabled={activeStep !== 5} variant="contained" color="secondary" onClick={handleCompletePayment}>
                                Complete Payment
                            </Button>
                            <NoteComponent />
                        </>
                }
            </Stack>

            <Box sx={{
                position: "fixed",
                width: "100%",
                bottom: 0,
                left: 0,
                bgcolor: "white",
                borderRadius: "15px 15px 0px 0px",
                boxShadow: "0px 3px 10px #00000066",
                padding: "16px 24px",
                display: { md: "none", xs: "block" }
            }}>
                <Stack spacing={2}>
                    {
                        transaction ?
                            <>
                                <Stack direction="row" alignItems="center" gap="10px">
                                    <Button variant="contained" color="secondary" onClick={() => setConfirmModal(true)}
                                        sx={{ width: "50%" }}
                                    >
                                        Confirm
                                    </Button>
                                    <Button sx={{ width: "50%", padding: '16px 24px !important' }} onClick={() => { setRejectModal(true); }} variant="outlined">Cancel</Button>
                                </Stack>
                            </>
                            :
                            <>
                                <Button fullWidth disabled={activeStep !== 5} variant="contained" color="secondary" onClick={handleCompletePayment}>
                                    Complete Payment
                                </Button>
                                <NoteComponent />
                            </>
                    }
                </Stack>
            </Box>
        </Stack>
    );
}


const PaymentDetails = ({ inModal }) => {
    const { amtPaid, NoFrax, fraxWithoutDisc, amount, amountAfterDiscount, transaction, discount, selectDiscount, couponCashback, highValueCashback, setHighValueCashback, pendingAmt, amtTobePaid, user, isFullPayment, discRPF, costFrax, crossedAmount } = useContext(InvestContext);
    const isSelectUser = user?.membershiptype === "SELECT";


    if (amount && amount >= 500000) setHighValueCashback(Math.floor(NoFrax / 144) * 20);
    return (
        <Stack spacing={4}>
            <Stack spacing={"10px"}>
                {isSelectUser && selectDiscount > 0 && fraxWithoutDisc !== NoFrax &&
                    <>
                        <Stack direction={"row"} justifyContent={"space-between"}>
                            <Typography variant="body3">Number of FRAX Without Discount</Typography>
                            <Typography variant="body3" sx={{ fontWeight: 500 }}>{convertToIndianFormat(fraxWithoutDisc, false) || "-"}</Typography>
                        </Stack>
                        <Divider />
                    </>
                }
                <Stack direction={"row"} justifyContent={"space-between"}>
                    {selectDiscount > 0 && isSelectUser && fraxWithoutDisc !== NoFrax ? <Typography variant="body3">Number of FRAX With Discount</Typography>
                        : <Typography variant="body3">Number of FRAX</Typography>
                    }
                    <Typography variant="body3" sx={{ fontWeight: 500 }}>{convertToIndianFormat(NoFrax, false) || "-"}</Typography>
                </Stack>
                <Divider />

                <Stack direction={"row"} justifyContent={"space-between"} sx={{ color: !isSelectUser ? '#BDBDBD' : '' }}>
                    <Stack direction={'row'} alignItems={'center'}>
                        <Typography variant="body3">Select Membership Discount</Typography>
                        <CustomIcon name="sale" sx={{ width: '30px', height: '22px', bgcolor: !isSelectUser ? "#BDBDBD" : "success.main", marginLeft: 2 }} />
                    </Stack>
                    {selectDiscount ? <Typography variant="body3" sx={{ fontWeight: 500, color: !isSelectUser ? '#BDBDBD' : "error.main" }}>
                        - {convertToIndianFormat(selectDiscount)}</Typography>
                        : "-"}
                </Stack>
                <Divider />


                {/* <Stack direction={"row"} justifyContent={"space-between"}>
                    <Typography variant="body3">High Value Order Cashback</Typography>
                    <Typography variant="body3" sx={{fontWeight:500}}>{convertToIndianFormat(highValueCashback) ||"-"}</Typography>
                    <Typography variant="body3" sx={{ fontWeight: 500 }}>{"-"}</Typography>
                </Stack>
                <Divider /> */}

                <Stack direction={"row"} justifyContent={"space-between"}>
                    <Typography variant="body3">Referal Cashback</Typography>
                    <Typography variant="body3" sx={{ fontWeight: 500 }}>{convertToIndianFormat(couponCashback) || "-"}</Typography>
                </Stack>
                <Divider />
                <Stack direction={"row"} justifyContent={"space-between"}>
                    <Typography variant="body3">Amount To be Paid Now</Typography>
                    <Typography variant="body3" sx={{ fontWeight: 500 }}>{convertToIndianFormat(Math.round(amtTobePaid)) || "-"}</Typography>
                </Stack>
                <Divider />
                {transaction?.payments?.length > 1 && <><Stack direction={"row"} justifyContent={"space-between"}>
                    <Typography variant="body3">Amount Paid {transaction?.status}</Typography>
                    <Typography variant="body3" sx={{ fontWeight: 500 }}>{convertToIndianFormat(Math.round(amtPaid)) || "-"}</Typography>
                </Stack>
                    <Divider /></>}
                {<><Stack direction={"row"} justifyContent={"space-between"}>
                    <Typography variant="body3">Payment Later</Typography>
                    <Typography variant="body3" sx={{ fontWeight: 500 }}>
                        {
                            !transaction ? convertToIndianFormat(Math.round(pendingAmt))
                                :
                                transaction?.status == "CREATED" ? convertToIndianFormat(Math.round(amount - amtTobePaid - selectDiscount))
                                    :
                                    convertToIndianFormat(Math.round(amount - pendingAmt))
                        }
                    </Typography>
                </Stack>
                    <Divider /></>}

                {/* {(transaction?.status == "CREATED") && <><Stack direction={"row"} justifyContent={"space-between"}>
                    <Typography variant="body3">Payment Later</Typography>
                    <Typography variant="body3" sx={{ fontWeight: 500 }}>{convertToIndianFormat(Math.round(amount - amtTobePaid)) || "-"}</Typography>
                </Stack>
                    <Divider /></>} */}

                <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                    <Typography variant="body3">Total Investment Amount</Typography>
                    {
                        amount && NoFrax && (amtPaid || amtTobePaid) ?
                            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                                <Typography variant="body3" sx={{ fontWeight: 500 }}>
                                    {convertToIndianFormat(Number(amountAfterDiscount))}
                                </Typography>

                                {selectDiscount > 0 && <Typography sx={{ pl: 2, fontWeight: 500, color: '#878787' }}>
                                    <span>
                                        <span style={{ textDecoration: "line-through", textDecorationThickness: "0.8px", fontSize: '15px' }}>{convertToIndianFormat(Math.ceil(crossedAmount))}</span>
                                    </span>
                                </Typography>
                                }
                            </Stack>
                            :
                            <Typography variant="h4" sx={{ fontWeight: 600 }}>-</Typography>
                    }
                </Stack>

            </Stack>
            {
                (transaction?.status == "CREATED" || inModal) &&
                <>
                    <Box>
                        <DividerComponent />
                    </Box>
                    <AccInformation />
                    {!transaction && <NoteComponent />}
                    <Box>
                        <DividerComponent />
                    </Box>
                    <TimerComponent />
                    {!inModal && <NoteComponent />}
                </>
            }
            {/* {
                transaction?.status=="PARTPAID" && 
                <>
                    <AccInformation />
                    <NoteComponent />
                </>
            } */}
        </Stack >
    )
}

export const AccInformation = () => {
    const { enqueueSnackbar } = useSnackbar();
    // const { user } = useContext(InvestContext);

    const copyText = (text) => {
        navigator.clipboard.writeText(text);
        enqueueSnackbar("Copied to clipboard.", { variant: "success" })
    }
    return (
        <Stack spacing={"10px"}>
            <Typography variant="body1" sx={{ fontWeight: 500 }}>Transfer Money to this bank account</Typography>
            <Box sx={{ borderRadius: 1, border: "1px solid #e1e1e1" }}>
                <Stack direction={"row"} px={3} py={1.5} justifyContent={"space-between"}>
                    <Typography variant="body2">Name: RealX Marketplace Pvt. Ltd.</Typography>
                    <Box onClick={() => copyText(`RealX Marketplace Pvt. Ltd.`)} sx={{ cursor: "pointer", }}>
                        <CustomIcon name="copy" />
                    </Box>
                </Stack>
                <Divider />
                <Stack direction={"row"} px={3} py={1.5} justifyContent={"space-between"}>
                    <Typography variant="body2">Account Number: 10114176799</Typography>
                    <Box onClick={() => copyText(`10114176799`)} sx={{ cursor: "pointer", }}>
                        <CustomIcon name="copy" />
                    </Box>
                </Stack>
                <Divider />
                <Stack direction={"row"} px={3} py={1.5} justifyContent={"space-between"}>
                    <Typography variant="body2">IFSC Code: IDFB0041359</Typography>
                    <Box onClick={() => copyText("IDFB0041359")} sx={{ cursor: "pointer", }}>
                        <CustomIcon name="copy" />
                    </Box>
                </Stack>
                <Divider />
                <Stack direction={"row"} px={3} py={1.5} justifyContent={"space-between"}>
                    <Typography variant="body2">Bank: IDFC First Bank</Typography>
                    <Box onClick={() => copyText("IDFC First Bank")} sx={{ cursor: "pointer", }}>
                        <CustomIcon name="copy" />
                    </Box>
                </Stack>
                <Divider />
                <Stack direction={"row"} px={3} py={1.5} justifyContent={"space-between"}>
                    <Typography variant="body2">Branch: Karve Nagar, Pune</Typography>
                    <Box onClick={() => copyText("Karve Nagar, Pune")} sx={{ cursor: "pointer", }}>
                        <CustomIcon name="copy" />
                    </Box>
                </Stack>
            </Box>
        </Stack>
    )
}


const OwnershipCard = () => {
    const { listing, selectedMode, setMode, transaction, minCoOwnAmount, minAmount, maxAmount } = useContext(InvestContext);
    const ownerShipModes = [
        {
            title: "Property Tokens",
            subtitle: "Right to Property Co-Ownership + Full benefits + Better Liquidity + Low min investment",
            icon: "token",
            info: <Typography variant="caption" color={"text.primary"}>
                Legal Contract giving you Right To Registered Co-ownership, recorded on Blockchain and issued as Property NFT. Carries all benefits of Co-Owner + better liquidity + smaller investment amount.
                <br />
                <a href="https://www.realx.in/how-it-works?tab=1" target="_blank">Read more about Property Ownership Modes</a>
            </Typography>,
            id: 0,
            disabled: false,
            visible: listing?.tokenisation,
        },
        {
            title: "Registered Co-ownership",
            subtitle: "Get your name as per registered property document",
            icon: "ownership",
            info: <Typography variant="caption" color={"text.primary"}>
                You become a registered co-owner of the property, commonly registered by the custodian on behalf of you as well as other co-owners.
                <br />
                {/* <Link component={RouterLink} to={"/how-it-works?tab=1"} target={"_blank"} state={{tab:1}}>Read more about Property Ownership Modes</Link> */}
                <a href="https://www.realx.in/how-it-works?tab=1" target="_blank">Read more about Property Ownership Modes</a>
            </Typography>,
            id: 1,
            disabled: maxAmount < minCoOwnAmount || listing?.status == "PRECLOSED",
            visible: listing?.ownership,
        }
    ]

    useEffect(() => {
        // console.log(listing,"tesssttt")
        if (listing && !listing?.tokenisation) {
            setMode(1);
        }
    }, [minCoOwnAmount, minAmount, maxAmount, listing])

    return (
        <InvestCardComponent title={`${!transaction ? "Select" : ""} Ownership Type`}>
            <Stack spacing={"10px"}>
                {
                    ownerShipModes.filter((k) => k.visible).map((e) => {
                        if (transaction) {
                            if (e.id === selectedMode) {
                                return (
                                    <Box
                                        onClick={() => setMode(e.id)}
                                        sx={{
                                            maxWidth: 440,
                                            padding: "14px 20px",
                                            border: "1px solid",
                                            borderColor: selectedMode === e.id ? "primary.main" : "divider",
                                            borderRadius: 1,
                                            bgcolor: selectedMode === e.id ? "primary.light" : "transparent",
                                            cursor: "pointer",
                                        }}
                                    >
                                        <Stack direction={"row"} spacing={2} alignItems={"center"}>
                                            <Box
                                                sx={{
                                                    width: 30,
                                                    height: 30,
                                                    minWidth: 30,
                                                    borderRadius: 0.5,
                                                    border: "1px solid",
                                                    bgcolor: selectedMode === e.id ? "primary.main" : "transparent",
                                                    borderColor: selectedMode === e.id ? "primary.main" : "primary.light",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <CustomIcon name={e.icon} sx={{ bgcolor: selectedMode === e.id ? "white" : "primary.main" }} />
                                            </Box>
                                            <Stack direction={"column"}>
                                                <Stack direction={"row"} alignItems={"center"} spacing={1.5}>
                                                    <Typography variant="body1" sx={{ fontWeight: 500 }}>{e.title}</Typography>
                                                    <TooltipComponent info={e.info} />
                                                </Stack>
                                                <Typography variant="caption" sx={{ fontWeight: 300 }}>{e.subtitle}</Typography>
                                            </Stack>
                                        </Stack>
                                    </Box>
                                )
                            }
                            else return "";
                        }
                        else {
                            return (
                                <Box
                                    onClick={() => e.disabled ? {} : setMode(e.id)}
                                    sx={{
                                        maxWidth: 440,
                                        padding: "14px 20px",
                                        border: "1px solid",
                                        borderColor: selectedMode === e.id ? "primary.main" : "divider",
                                        borderRadius: 1,
                                        bgcolor: selectedMode === e.id ? "primary.light" : "transparent",
                                        cursor: "pointer"
                                    }}
                                >
                                    <Stack direction={"row"} spacing={2} alignItems={"center"}>
                                        <Box
                                            sx={{
                                                width: 30,
                                                height: 30,
                                                minWidth: 30,
                                                borderRadius: 0.5,
                                                border: "1px solid",
                                                bgcolor: selectedMode === e.id ? "primary.main" : "transparent",
                                                borderColor: selectedMode === e.id ? "primary.main" : "primary.light",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center"
                                            }}
                                        >
                                            <CustomIcon name={e.icon} sx={{ bgcolor: e.disabled ? "text.disabled" : selectedMode === e.id ? "white" : "primary.main" }} />
                                        </Box>
                                        <Stack direction={"column"}>
                                            <Stack direction={"row"} alignItems={"center"} spacing={1.5}>
                                                <Typography variant="body1" sx={{ fontWeight: 500, color: e.disabled ? "text.disabled" : "" }}>{e.title}</Typography>
                                                <TooltipComponent disabled={e.disabled} info={e.info} />
                                            </Stack>
                                            <Typography variant="caption" sx={{ fontWeight: 300, color: e.disabled ? "text.disabled" : "" }}>{e.subtitle}</Typography>
                                        </Stack>
                                    </Stack>
                                </Box>
                            )
                        }
                    })
                }
            </Stack>
        </InvestCardComponent>
    );
}

const AmountCard = () => {
    const { amount, setAmount, calculateAmount, minAmount, editable, transaction, selectedMode, maxAmount } = useContext(InvestContext);
    // const []
    return (
        <InvestCardComponent title={`${!transaction ? "Enter" : ""} Investment Amount`} info={false}>

            <Stack direction={"row"} spacing={2} justifyContent={"space-between"} alignItems={"center"}>
                <Typography variant="body2" sx={{ fontFamily: "Roboto", fontWeight: 300, mb: 1 }}> Start Investment  from  &nbsp;
                    <Typography variant="body3" sx={{ fontFamily: "Roboto", display: "inline-block", fontWeight: 900, fontStyle: "italic" }}>{convertToIndianFormat(minAmount)}</Typography>
                    &nbsp;  up to &nbsp;
                    <Typography variant="body3" sx={{ fontFamily: "Roboto", display: "inline-block", fontWeight: 900, fontStyle: "italic" }}>{convertToIndianFormat(maxAmount)}</Typography>

                </Typography>


                {/* <Typography variant="body2" sx={{fontFamily:"Roboto",fontWeight:300,mb:1}}>Min Investment: 
            <Typography variant="body2" sx={{fontFamily:"Roboto",display:"inline-block",fontWeight:900,fontStyle:"italic"}}>₹ {convertToIndianFormat(minAmount)}</Typography>
            </Typography> */}

            </Stack>


            <TextField
                disabled={transaction}
                autoComplete="off"
                inputProps={{
                    readOnly: !editable,
                    style: {
                        fontSize: "22px",
                        padding: "12px 16px",
                        fontWeight: "600",
                    }
                }}
                value={amount}
                onChange={(e) => {
                    const inputValue = e.target.value;
                    if (RegExp('^[0-9]*$').test(inputValue)) {
                        setAmount(inputValue);
                    }
                }}
                InputProps={{
                    sx: {
                        padding: "0px !important"
                    },


                    startAdornment: <InputAdornment position="start">
                        <Typography variant="h4" sx={{ fontFamily: "Roboto", color: "#9F9F9F", fontWeight: 400, ml: 3 }}>
                            ₹
                        </Typography>
                    </InputAdornment>,
                    endAdornment: !transaction && <InputAdornment sx={{ ml: 0 }} position="end">
                        <Button onClick={calculateAmount}
                            disabled={editable && !(parseInt(minAmount) <= parseInt(amount))}
                            fullWidth variant={editable ? "contained" : "text"} color="secondary">
                            {editable ? "Proceed" : "Edit"}
                        </Button>
                    </InputAdornment>

                }}
            >

            </TextField>
            <Box mt={"16px"}>

                <Stack direction={"row"} alignItems={{ md: "center", xs: "flex-start" }} spacing={1} sx={{
                    px: 1.5,
                    py: 0.5,
                    bgcolor: "warning.light",
                    width: { md: "max-content", xs: "100%" },
                    borderRadius: 0.5,
                }}>
                    <CustomIcon name="important" sx={{ width: 12, bgcolor: "warning.main" }} />
                    <Typography variant="body2" sx={{ fontFamily: "Roboto", fontWeight: 400, color: "warning.dark", fontStyle: "italic" }}>
                        Investment amount will be automatically adjusted to closest multiple value
                    </Typography>
                </Stack>
            </Box>
        </InvestCardComponent>
    )
}

const BenificiaryCard = () => {
    const ref = useRef()
    const relationOptions = [
        {
            title: "Parent",
            value: "Parent",
        },
        {
            title: "Spouse",
            value: "Spouse",
        },
        {
            title: "In Law",
            value: "In Law",
        },
        {
            title: "Sibling",
            value: "Sibling",
        },
        {
            title: "Child",
            value: "Child",
        },
        {
            title: "Other Relative",
            value: "Other Relative",
        },
        {
            title: "Friend / Not Related",
            value: "Friend / Not Related",
        },
        {
            title: "Nominee / Attorney",
            value: "Nominee / Attorney",
        },
    ];

    const { beneficiaryInfo, setBeneficiaryInfo, transaction } = useContext(InvestContext)

    const [ren, setRen] = useState(false);
    useEffect(() => {
        if (ref.current && !ren) {
            setRen(true);
        }
    }, [ref, ren])

    return (
        <InvestCardComponent title={`${!transaction ? "Add" : ""} Beneficiary/Successor`}>
            <Stack direction={{ md: "row", xs: "column" }} spacing={{ md: 4, xs: 2 }}>
                <Stack direction={"column"} spacing={0.5} flex={1}>
                    <Typography
                        variant="caption">Enter Full Legal Name*</Typography>
                    <TextField
                        disabled={transaction}
                        inputProps={{
                            readOnly: transaction
                        }}
                        value={beneficiaryInfo.name}
                        onChange={(e) => {
                            const inputValue = e.target.value;
                            if (/^[a-zA-Z ]*$/.test(inputValue)) {
                                setBeneficiaryInfo({ ...beneficiaryInfo, name: inputValue });
                            }
                        }} />
                </Stack>
                <Stack direction={"column"} spacing={0.5} flex={1}>
                    <Typography variant="caption">Select Relationship*</Typography>
                    <Select
                        disabled={transaction}
                        ref={ref}
                        //   value={age}
                        //   onChange={handleChange}
                        // disabled={ren}
                        inputProps={{
                            readOnly: transaction
                        }}
                        MenuProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                            },
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "left"
                            },
                            anchorEl: ref.current,
                        }}
                        // {...props}
                        value={beneficiaryInfo.relation}
                        onChange={(e) => setBeneficiaryInfo({ ...beneficiaryInfo, relation: e.target.value })}
                        displayEmpty
                        IconComponent={() => <ExpandMoreIcon sx={{ width: 18, height: 18, color: "text.disabled" }} />}
                    >
                        {
                            relationOptions.map((e) => {
                                return <MenuItem value={e.value}><Typography variant="placeHolder">{e.title}</Typography></MenuItem>
                            })
                        }
                    </Select>
                </Stack>
            </Stack>
        </InvestCardComponent>
    )
}

const CouponCard = () => {
    const { coupon, setCoupon, validateCoupon, editableCoupon, transaction } = useContext(InvestContext);
    return (
        <InvestCardComponent title={"Have a Referal Code?"} info={false}>

            <TextField
                disabled={transaction}
                autoComplete="off"
                inputProps={{
                    readOnly: !editableCoupon,
                    style: {
                        fontSize: "22px",
                        padding: "12px 16px",
                        fontWeight: "600",
                    }
                }}
                value={coupon}
                onChange={(e) => {
                    const inputValue = e.target.value;
                    setCoupon(inputValue);
                }}
                InputProps={{
                    sx: {
                        padding: "0px !important"
                    },

                    endAdornment: !transaction && <InputAdornment sx={{ ml: 0 }} position="end">
                        <Button onClick={validateCoupon}
                            disabled={(coupon.length < 6)}
                            fullWidth variant={editableCoupon ? "contained" : "text"} color="secondary">
                            {editableCoupon ? "Apply" : "Edit"}
                        </Button>
                    </InputAdornment>

                }}
            >

            </TextField>

        </InvestCardComponent>
    )
}

const DLTAccountsCard = () => {
    const { availableBlockchainAccounts,
        selectedBlockchainAccountIndex,
        setSelectedBlockchainAccountIndex,
        changeBlockchainAccountModalOpen,
        setChangeBlockchainAccountModalOpen,
        selectedMode, selectedBlockChainAccount, setSelectedBloackChainAccount, chosenDltAccountAfterCommitment, accountSelected
    } = useContext(InvestContext);

    const [newBlockchainAccountIndex, setNewBlockchainAccountIndex] = useState(0);

    const handleChange = () => {
        setSelectedBlockchainAccountIndex(newBlockchainAccountIndex)
        setSelectedBloackChainAccount(availableBlockchainAccounts[newBlockchainAccountIndex]?.publicAddress);
    };

    useEffect(() => {

        if (chosenDltAccountAfterCommitment == undefined || chosenDltAccountAfterCommitment == null && availableBlockchainAccounts?.length > 0) {
            setSelectedBloackChainAccount(availableBlockchainAccounts[0]?.publicAddress);
            setNewBlockchainAccountIndex(0);
            setSelectedBlockchainAccountIndex(0);
        }
    }, [availableBlockchainAccounts, chosenDltAccountAfterCommitment]);

    return (
        <>
            {selectedMode === 0 && (
                <InvestCardComponent title={"Select DLT Account for Investment:"}>
                    {changeBlockchainAccountModalOpen && <AreYouSureModal
                        open={changeBlockchainAccountModalOpen}
                        setOpen={setChangeBlockchainAccountModalOpen}
                        handleYes={() => handleChange(selectedBlockchainAccountIndex)}
                        message={"Are You Sure About Changing DLT Account for this Transaction?"}
                        note={"New DLT Account is Only Applicable for This Specific Transaction."}
                        publicAddress={availableBlockchainAccounts[newBlockchainAccountIndex]?.publicAddress}
                        publicAddressMessage={"Your New DLT Account Address"}
                    />}
                    <Stack spacing={"20px"} direction="column">
                        {availableBlockchainAccounts?.length === 0 ? (
                            <Typography variant="body2">No available blockchain accounts</Typography>
                        ) : (
                            availableBlockchainAccounts?.map((account, index) => (
                                <Box key={index}>
                                    <FormControlLabel
                                        sx={{ alignItems: 'center' }}
                                        control={
                                            <Radio
                                                disabled={chosenDltAccountAfterCommitment && (chosenDltAccountAfterCommitment != account?.publicAddress)}
                                                checked={selectedBlockChainAccount === account?.publicAddress}
                                                onChange={(event) => {
                                                    setNewBlockchainAccountIndex(index);
                                                    setChangeBlockchainAccountModalOpen(true);
                                                }}
                                                value={index}

                                            />
                                        }
                                        label={`... ${account?.publicAddress?.slice(-18)} - ${account?.name?.slice(0, 20)}`} // Assuming 'name' is a property of the account object
                                    />
                                </Box>
                            ))
                        )}
                    </Stack>
                </InvestCardComponent>
            )}
        </>
    );
};

const InvestmentAccounts = () => {
    const { setInvestmentAccountModalOpen, investmentAccountModalOpen, accountSelected, setAccountSelected, user, chosenAccountAfterCommitment, familyMembers,trust, entityId, setEntityId } = useContext(InvestContext);
    const [account, setAccount] = useState(accountSelected);

    const handleSelectChange = (event) => {
        const newValue = event.target.value;
        setInvestmentAccountModalOpen(true);
        setAccount(newValue);
    };

    return (
        <>
            {(user?.isHufHead || familyMembers?.length > 0||trust?.length>0) && (
                <InvestCardComponent title={"Select Account for Investment"}>
                    <AreYouSureModal
                        open={investmentAccountModalOpen}
                        setOpen={setInvestmentAccountModalOpen}
                        handleYes={() => {
                            setInvestmentAccountModalOpen(false);
                            setAccountSelected(account);
                        }}
                        message={"Are You Sure About Changing investment Account for this Transaction?"}
                        note={"New investment Account is Only Applicable for This Specific Transaction."}
                        publicAddress={account + " Account"}
                        publicAddressMessage={"Your new selected account"}
                    />
                    <Box>
                        <Select
                            value={accountSelected}
                            disabled={chosenAccountAfterCommitment}
                            onChange={handleSelectChange}
                        >
                            <MenuItem value="Personal" onClick={() => setEntityId(null)}>Personal Account</MenuItem>
                            {user?.isHufHead && (
                                <MenuItem value="HUF" onClick={() => setEntityId(user?.hufDetails[0]?._id)}>HUF Account</MenuItem>
                            )}
                            {familyMembers?.length > 0 &&
                                familyMembers.map((member, index) => (
                                    <MenuItem key={index} value={member.username} onClick={() => setEntityId(member.familyMemberId)}>
                                        {member.username} ({member.relation})
                                    </MenuItem>
                                ))
                            }
                            {trust?.length > 0 &&
                               <MenuItem value="Trust" onClick={() => setEntityId(trust[0]?.entityId)}>Trust Account</MenuItem>
                            }
                        </Select>
                    </Box>
                </InvestCardComponent>
            )}
        </>
    );
};

const ConditionsCard = () => {
    const { termsAccepted, setAccepted, selectedMode } = useContext(InvestContext);
    const [conditions, setConditions] = useState([
        {
            title: "I consent to make my investment towards equitable, undivided co-ownership of the selected property as a financial investment and hold it in digital form through a secure registry maintained by Realx for the same",
            checked: termsAccepted,
        },
        {
            title: "I agree to the terms of the Instrument of Partition and consent to make it part of the instrument of Purchase of the selected property.",
            checked: termsAccepted,
        },
        {
            title: "I mandate RealX Trust I to register the property on my behalf and hold the title on behalf of me and all the co-owners.",
            checked: termsAccepted,
        },
        {
            title: "I mandate Realx to collect the rent and other income from the above property and process it in my bank account directly, after appropriate deductions towards various applicable fees and charges.",
            checked: termsAccepted,
        },
    ]);

    const handleChange = (value, index) => {
        setConditions(prev => prev.map((e, i) => ({
            ...e,
            checked: i === index ? value : e.checked
        })))
    }

    const selectAll = (value) => {
        setConditions(prev => prev.map((e, i) => ({
            ...e,
            checked: value
        })))
    }

    useEffect(() => {
        setAccepted(!(conditions.find((e) => e.checked === false)))
        // eslint-disable-next-line
    }, [conditions])

    useEffect(() => {
        if (selectedMode === 1) {
            setConditions([
                {
                    title: "I consent to make my investment towards equitable, undivided co-ownership of the selected property as a financial investment and hold it in digital form through a secure registry maintained by RealX for the same.",
                    checked: termsAccepted,
                },
                {
                    title: "I agree and authorise the usage of my investment towards payment as consideration for purchase of the property I'm investing in.",
                    checked: termsAccepted,
                },
                {
                    title: "I agree to the terms of the Instrument of Partition and consent to make it part of the instrument of Purchase of the selected property.",
                    checked: termsAccepted,
                },
                {
                    title: "I mandate the Custodian to register the property on my behalf and hold the title on behalf of me and all the co-owners.",
                    checked: termsAccepted,
                },
                {
                    title: "I mandate RealX to collect the rent and other income from the above property and process it in my bank account or wallet directly, after appropriate deductions towards various applicable fees and charges.",
                    checked: termsAccepted,
                },
            ])
        }
        else if (selectedMode === 0) {
            setConditions([
                {
                    title: "I consent to make my investment towards Property Tokens of the selected property as a financial investment and hold it in digital form as Non Fungible Tokens issued to me in my wallet. I understand that the NFT represents Right to Co-Ownership.",
                    checked: termsAccepted,
                },
                {
                    title: "I understand that NFT represents a beneficial interest, but not yet a direct right in the property until I exerccise the right, subject to eligibility and other conditions, to convert my ownership into Registered Co-Owner by exhausting the Token.",
                    checked: termsAccepted,
                },
                {
                    title: "I consent to agree to the Registered Co-Ownership terms should I decide to convert my  Ownership Mode to Registered Co-Ownership.",
                    checked: termsAccepted,
                },
            ])
        }
        selectAll(false);
        // eslint-disable-next-line
    }, [selectedMode])

    return (
        <InvestCardComponent title={"Terms & Conditions:"}>
            <Stack spacing={"20px"} direction="column">
                {
                    conditions.map((e, i) => {
                        return (
                            <Box key={i}>
                                <FormCheckBox
                                    value={e.checked}
                                    onChange={(j) => handleChange(j.target.checked, i)}
                                    label={e.title}
                                />
                            </Box>
                        )
                    })
                }
                <Box>
                    <FormCheckBox
                        value={termsAccepted}
                        onChange={(j) => { setAccepted(j.target.checked); selectAll(j.target.checked) }}
                        labelStyle={{
                            fontWeight: 600
                        }}
                        label={"I understand and consent to above."}
                    />
                </Box>
            </Stack>
        </InvestCardComponent>
    )
}

const InvestCardComponent = (props) => {
    const ref = useRef();
    useEffect(() => {
        if (ref && ref.current) {
            ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
        }
    }, [])
    return (
        <Card ref={ref} sx={{ borderRadius: "10px" }}>
            <CardContent sx={{ padding: { md: 3, xs: 2 } }} >
                <Stack direction={"row"} alignItems={"center"} spacing={1.5}>
                    <Typography variant="body3" sx={{ fontWeight: 500 }}>{props.title}</Typography>
                    {
                        props.info &&
                        <CustomIcon name="info" sx={{ width: 12, height: 12 }} />
                    }
                </Stack>
                <Box mt={2}>
                    {
                        props.children
                    }
                </Box>
            </CardContent>
        </Card>
    )
}



export const NoteComponent = () => {
    const { transaction } = useContext(InvestContext);
    return (
        <Stack direction={"row"} spacing={"10px"}>
            <CustomIcon name="important" sx={{ width: 32, bgcolor: "primary.main", mt: 0.5 }} />
            {
                transaction ?
                    <Typography variant="body3" sx={{ fontWeight: 300, lineHeight: 1.3 }}>
                        After you've made the transfer in the give bank account, click on the Below button to initiate verification and transfer of frax.
                    </Typography>
                    :
                    <Typography variant="body3" sx={{ fontWeight: 300, lineHeight: 1.3 }}>
                        Please complete the Bank Transfer and Confirm the Transaction here within <span style={{ fontWeight: 600, fontStyle: "italic" }}>72 hours</span> to hold the allotment.

                    </Typography>
            }
        </Stack>
    )
}

const TimerComponent = () => {
    const { transaction } = useContext(InvestContext);
    const [time, setTime] = useState("72h : 00m : 00s")

    useEffect(() => {
        getTime();
        let interval = setInterval(() => {
            getTime();
        }, 1000);
        return () => {
            clearInterval(interval);
        }
        // eslint-disable-next-line
    }, [transaction])

    const getTime = () => {
        if (transaction) {
            let exDate = moment.unix(transaction.expiryDate);
            let today = moment();
            let ms = exDate.diff(today);
            if (ms <= 0) {
                setTime("00h : 00m : 00s");
                return;
            }
            setTime(convertMsToTime(ms))
            // console.log(ms);
            // setTimeout(()=>{
            // getTime();
            // },1000)
        }
        else {
            setTime("72h : 00m : 00s")
        }
    }
    return (
        <Stack spacing={"10px"}>
            <Typography variant="body1" sx={{ fontWeight: 500 }}>Investment reservation will end in:</Typography>
            <Box
                sx={{
                    py: "12px",
                    width: "100%",
                    borderRadius: "10px",
                    border: "1px solid",
                    borderColor: "primary.main",
                    bgcolor: "primary.light",
                    textAlign: "center"
                }}
            >
                <Typography variant="h4" sx={{ color: "primary.main", fontWeight: 600 }}>{time}</Typography>
            </Box>
        </Stack>
    )
}

const TooltipComponent = ({ info, disabled }) => {
    const [open, setOpen] = useState(false);
    return (
        <HtmlTooltip
            open={open}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            leaveTouchDelay={5000}
            title={
                <Box
                    sx={{
                        bgcolor: "primary.lighter",
                        borderRadius: 1,
                        padding: 2
                    }}
                >
                    {
                        info
                    }
                    {/* <Typography dangerouslySetInnerHTML={{__html: info}} variant="caption" color={"text.primary"}></Typography> */}
                </Box>
            }
        >
            <Box onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setOpen(true)
            }}>
                <CustomIcon name="info" sx={{ width: 12, height: 12, bgcolor: disabled ? "text.disabled" : "#000" }} />
            </Box>
        </HtmlTooltip>
    )
}
