import store from "../redux/store";
import { server } from "../utils/server";



const getMyWatchlist = () =>{
    const user = store.getState().user.data;
    const data = {
        investorId:user._id
    }
    return server.post("/investor/features/getWatchlist",data)
    .then((res)=>{
        return res.data;
    })
    .catch((err)=>{
        console.log(err)
    });
}

const removeDLTAccount = (data) =>{
    return server.post("dlt/removeDlt",data)
    .then((res)=>{
        return res.data;
    })
    .catch((err)=>{
        console.log(err)
    });
}

const updatePrimaryDLTAccount = (data) =>{
    return server.post("dlt/updateDlt",data)
    .then((res)=>{
        return res.data;
    })
    .catch((err)=>{
        console.log(err)
    });
}

const updateDLTCreationStatus = (data) =>{
    return server.post("/investor/features/updateDLTCreationStatus",data)
    .then((res)=>{
        return res.data;
    })
    .catch((err)=>{
        console.log(err)
    });
}

const toggleWatchlist = (id) =>{
    const user = store.getState().user.data;
    const data = {
        listingId:id,
        investorId:user._id
    }
    return server.post("/investor/features/toggleWatchlist",data)
    .then((res)=>{
        return res.data;
    })
    .catch((err)=>{
        console.log(err)
    });
}

const addInvite = (data) =>{
    return server.post("/invite/addInvite",data)
    .then((res)=>{
        return res.data;
    })
    .catch((err)=>{
        console.log(err)
    });
}


const getATransactionDetails = (data) =>{
    return server.post('/transactions/get/Byinvestor', data)
    .then((res)=>{
        return res.data;
    })
    .catch((err)=>{
        console.log(err);
    });
}


const createSelectUser = (data) => {
    const { investorId, amount, membershiptype } = data;

    const queryParams = new URLSearchParams({
        investorId,
        amount,
        membershiptype
      });

    // const queryParams = new URLSearchParams({
    //   emailId,
    //   membershiptype,
    //   mobileNumber,
    //   username,
    //   amount
    // });
  
    // const url = `/investor/auth/addnewselectinvestor`;
    const url = `/investor/auth/purchasemembership`;
    // const url = `/investor/auth/addnewselectinvestor?${queryParams.toString()}`;
  
    return server
      .post(url, data)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  };



   const createHuf = async (data)=>{
   const config = { headers: {'Content-Type': 'multipart/form-data'}};
   return await server.post('/huf/auth/registration',data,config)
   .then((res)=>{
     return  res.data
   })
   .catch((err)=>{
       console.log(err);
       return err
   })
}


   const getHufDetails = async (investorId)=>{
    return server.get(`/huf/auth/${investorId}`)
    .then((res)=>{
        return res.data
    })
    .catch((error)=>{
        return error
    })
   }
   
   const updateHufDetails = async (_id,data)=>{
    const config = { headers: {'Content-Type': 'multipart/form-data'}};
    return server.put(`/huf/auth/update/${_id}`,data,config)
    .then((res)=>{
        return res.data
    })
    .catch((error)=>{
        return error
    })
   }

   const deleteHufDetails = async (_id,investorId)=>{  
    return server.delete('/huf/auth/delete/',{ data: { id: _id, investorId: investorId }})
    .then((res)=>{
        return res.data
    })
    .catch((error)=>{
        return error
    })
   }

//    const getDltWalletByHuf = (investorId) => {
//     return server.get(`investor/auth/getDltWallet/${investorId}`)
//         .then(res => {
            
//             return res.data;
//         })
//         .catch(err => {
//             console.log(err);
//             return null;
//         })
// }

    const getHufById = async(id)=>{
        return server.get(`realXAdmin/huf/${id}`)
        .then((res)=>{
            return res.data
        })
        .catch((error)=>{
            return error
        })
    }

    const downloadPublicAddressAndMemonic = async(data)=>{
        return server.post(`dlt/download`,data)
        .then((res)=>{
            return res.data
        })
        .catch((error)=>{
            return error
        })
    }
    const createPublicAddressAndMemonic = async(data)=>{
        return server.post(`dlt/createDlt`,data)
        .then((res)=>{
            return res.data
        })
        .catch((error)=>{
            return error
        })
    }
    const getDltWallet = async(id)=>{
        return server.get(`dlt/getdltWallet/${id}`)
        .then((res)=>{
            return res.data
        })
        .catch((error)=>{
            return error
        })
    }

const userServices = {
    getMyWatchlist,
    toggleWatchlist,
    addInvite,
    createSelectUser,
    getATransactionDetails,
    removeDLTAccount,
    updatePrimaryDLTAccount,
    updateDLTCreationStatus,
    createHuf,
    getHufDetails,
    updateHufDetails,
    deleteHufDetails,
    getHufById,
    downloadPublicAddressAndMemonic,
    createPublicAddressAndMemonic ,
    getDltWallet

}

export default userServices;