import React, { useState, useEffect } from "react";
import AreYouSureModal from "../../../../components/modals/areYouSureModal";
import authServices from "../../../../services/authServices";
import CustomIcon from "../../../../components/customIcon";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import moment from "moment";
import {
  Button,
  Card,
  CardContent,
  Divider,
  Stack,
  Typography,
  Box,
} from "@mui/material";
import userServices from "../../../../services/userServices";
import { useSnackbar } from "notistack";
import hufService from "../../../../services/hufService";
import TrustCreateDLTModel from "./TrustCreateDLTModel";
import trustServices from "../../../../services/trustServise";
const TrustDltWallet = ({ entityId, getTrustDetails }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [primaryOpen, setPrimaryOpen] = useState(false);
  const [availableBlockchainAccounts, setAvailableBlockchainAccounts] =
    useState([]);
  const [blockchainAccountModal, setBlockchainAccountModal] = useState(false);
  const [trustAccount, setTrustAccount] = useState();
  const [dltWalletId, setDltWalletId] = useState();
  const { enqueueSnackbar } = useSnackbar();

  const getHufAccount = async () => {
    const res = await trustServices.getTrustById(entityId);
    if (res && res.success) {
      console.log(res)
      setTrustAccount(res.data[0]);
    } else {
      enqueueSnackbar("Somthing went wrong", {
        variant: "error",
        autoHideDuration: 4000,
      });
    }
  };
  useEffect(() => {
    getHufAccount();
  }, [blockchainAccountModal]);

  const handlePrimary = async () => {
      const res = await userServices.updatePrimaryDLTAccount({
        entityId: entityId,
        dltWalletId: dltWalletId,
        isSection:"trust"
      });
  
      if (res && res.success) {
        getHufAccount();
  
      }
    };

    const handleRemove = async () => {
      const res = await userServices.removeDLTAccount({
        entityId: entityId,
        dltWalletId: dltWalletId,
        isSection:"trust"
      });
      if (res && res.success) {
        getHufAccount();
        enqueueSnackbar("DLT Account Deleted Successfully!", {
          variant: "success",
          anchorOrigin: { horizontal: "right", vertical: "top" },
          autoHideDuration: 3000,
        });
      } else
        enqueueSnackbar(
          "This DLT Account is Holding Some Digital Assets in it. Please Contact RealX Admin for More Information!",
          {
            variant: "error",
            anchorOrigin: { horizontal: "center", vertical: "bottom" },
            autoHideDuration: 9000,
          }
        );
    };
  const fetchDltWallet = async () => {
    let dltWallet = await userServices.getDltWallet(entityId);
    setAvailableBlockchainAccounts(dltWallet.data);
  };

  useEffect(() => {
    fetchDltWallet();
  }, [trustAccount]);

  const handleDLTCreationStatus = async (trustId, status) => {
    const res = await trustServices.updateDLTCreationStatusTrust({
      trustId: trustId,
      status,
    });
    if (res && res.success) {
      getHufAccount();
    }
  };


  const copyUrl = (account) => {
    navigator.clipboard.writeText(account);
    enqueueSnackbar("Copied to Clickboard", {
      variant: "success",
      anchorOrigin: { horizontal: "right", vertical: "top" },
      autoHideDuration: 800,
    });
  };

  return (
    <Stack spacing={3} width={"50%"}>
      <AreYouSureModal
        open={primaryOpen}
        setOpen={setPrimaryOpen}
        handleYes={handlePrimary}
        message={"Are You Sure About Updating your Primary DLT Account?"}
        publicAddress={availableBlockchainAccounts[currentIndex]?.publicAddress}
        publicAddressMessage={"Your New Primary DLT Account Address"}
      />
      <AreYouSureModal
        open={deleteOpen}
        setOpen={setDeleteOpen}
        handleYes={handleRemove}
        message={"Are You Sure About Permanently Deleting this DLT Account?"}
        note={"This action can not be undo!"}
        publicAddress={availableBlockchainAccounts[currentIndex]?.publicAddress}
        publicAddressMessage={"DLT Account Address To Be Removed"}
      />

      <Typography fontSize={"14px"}>
        Available Blockchain Accounts
      </Typography>

      <Stack
        flexDirection={"column"}
        sx={{
          overflowX: { xs: "auto", sm: "auto", md: "hidden" },
          flexWrap: "wrap",
        }} gap={2}>
        {availableBlockchainAccounts.length > 0 ? (
          availableBlockchainAccounts
            .sort((a, b) => (b.isPrimary ? 1 : -1) - (a.isPrimary ? 1 : -1)) // Sort primary account first
            .map((account, index) => {
              return (
                <>
                  <Stack
                    flexDirection={"row"}
                    columnGap={{ xs: 1, sm: 2 }}
                    sx={{
                      width: {
                        lg: "100%",
                        md: "65%",
                        sm: "85%",
                        xs: "min-content",
                      },
                    }}>
                    <Stack
                      flexDirection={"row"}
                      sx={{
                        padding: "15px",
                        borderRadius: "10px",
                        boxShadow: "0px 1px 6px 0px #51515129",
                        width: "100%",
                      }}>
                      <Box sx={{ padding: "2px" }} width={"100%"}>
                        <Box sx={{ display: "flex" }} justifyContent={'space-between'}>
                          {" "}
                          <Typography sx={{ fontSize: "16px !important" }}>
                            #{index + 1}. ...{account.publicAddress?.slice(-25)}
                          </Typography>
                          <Box
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              copyUrl(account.publicAddress);
                            }}
                            sx={{
                              bgcolor: "#f4f6f7",
                              padding: "7px",
                              borderRadius: "50%",
                              marginLeft: "10px",
                              hight: "20px",
                              width: "30px",
                            }}>
                            <CustomIcon
                              name="copy"
                              sx={{
                                bgcolor: "#FFF",
                                backgroundColor: "#333333",
                                cursor: "pointer",
                              }}
                            />
                          </Box>
                          <Box sx={{ marginLeft: "4px" }}>
                            {account.isPrimary ? (
                              <>
                                <Typography
                                  sx={{
                                    color: "white",
                                    background: "#21ab37",
                                    padding: "2px 15px !important",
                                    fontSize: "12px !important",
                                    border: "1px solid #21ab37",
                                    borderRadius: "10px",
                                  }}>
                                  Primary Account
                                </Typography>
                              </>
                            ) : (
                              <Stack
                                flexDirection={"row"}
                                alignItems={"center"}
                                columnGap={{ xs: 1, sm: 2 }}
                                sx={{ m: "0 !important" }}>
                                <Button
                                  variant="contained"
                                  sx={{
                                    p: "4px 15px !important",
                                    fontSize: "12px !important",
                                    marginLeft: "5px",
                                  }}
                                  onClick={() => {
                                    setCurrentIndex(index);
                                    setPrimaryOpen(true);
                                    setDltWalletId(account._id)
                                  }}>
                                  Make Primary
                                </Button>
                                <RemoveCircleOutlineIcon
                                  sx={{ color: "red", cursor: "pointer" }}
                                  onClick={() => {
                                    setCurrentIndex(index);
                                    setDeleteOpen(true);
                                    setDltWalletId(account._id)
                                  }}
                                />
                              </Stack>
                            )}
                          </Box>
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            paddingTop: "10px",
                          }}>
                          <Box>
                            <Typography sx={{ fontWeight: "600", fontSize: "16px !important" }}>
                              {account?.name?.slice(0, 30)}
                            </Typography>
                          </Box>
                          <Box>
                            <Typography sx={{ fontSize: "16px !important" }}>
                              {account?.created_at
                                ? ` Created On: ${moment(
                                  account?.created_at
                                ).format("DD-MM-YYYY")}`
                                : ""}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Stack>
                  </Stack>
                </>
              );
            })
        ) : (
          <>
            <Typography variant="subtitle1">
              You have not made any DLT accounts yet
            </Typography>
          </>
        )}
      </Stack>

      <Stack flexDirection={"row"} gap={1} justifyContent={'center'}>
        <Button
          onClick={() =>
            trustAccount?.walletInfo?.isAllowedToCreateDLT == "APPROVED"
              ? setBlockchainAccountModal(true)
              : handleDLTCreationStatus(trustAccount?._id, "PENDING")
          }
          variant="contained"
          sx={{ width: "max-content", py: "10px !important" }}
          disabled={
            availableBlockchainAccounts?.length > 1 ||
            trustAccount?.walletInfo?.isAllowedToCreateDLT == "PENDING" ||
            ["PENDING", "REJECTED"].includes(trustAccount?.status)
          }>
          {trustAccount?.walletInfo?.isAllowedToCreateDLT == "APPROVED"
            ? "Create a New DLT Account"
            : trustAccount?.walletInfo?.isAllowedToCreateDLT == "PENDING"
              ? "Request Submitted to RealX Admin"
              : "Request to Create a New DLT Account"}
        </Button>

        {trustAccount?.walletInfo?.isAllowedToCreateDLT == "PENDING" && (
          <Button
            variant="contained"
            sx={{ py: "7px !important" }}
            onClick={() => handleDLTCreationStatus(trustAccount?._id, "CREATED")}>
            Cancel Request
          </Button>
        )}
      </Stack>
      {blockchainAccountModal && (
        <TrustCreateDLTModel
          open={blockchainAccountModal}
          setOpen={setBlockchainAccountModal}
          trust={trustAccount}
          getTrustDetails={() => getTrustDetails()}
        />
      )}
    </Stack>
  );
};

export default TrustDltWallet;
