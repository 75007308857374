import { Box, Button, Card, CardContent, Dialog, Divider, Stack, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useState, useEffect } from "react";
import transactionServices from "../../services/transactionServices";
import { DividerComponent } from "../miniComponents";
import CloseIcon from '@mui/icons-material/Close';
import { convertToIndianFormat } from "../../utils/functions";
import { AccInformation, TimerComponent } from "../../pages/marketPlace/property/investPage";
import { NoteComponent } from "../../pages/marketPlace/property/investPage";
import { useSelector } from "react-redux";

export default function CompletePaymentModal({ open, handleClose = () => { }, data,paymentDetails,isFullPayment, getTransaction = () => { } }) {
    const { enqueueSnackbar } = useSnackbar();

    const [submitting, setSubmitting] = useState(false);

    const handleSubmit = async () => {
        if (data) {
            setSubmitting(true);
            const req = await transactionServices.createTransaction({
                ...data,
                isFullPayment,
                paymentMethod: "DBT"
            });
            if (req && req.success) {
                enqueueSnackbar("Success", { variant: "success" });
                getTransaction();
                handleClose();
            }
            else {
                if (req.data) enqueueSnackbar(req.data.message, { variant: "error" });
                else enqueueSnackbar("Something went wrong!!", { variant: "error" });
            }
            setSubmitting(false);
        }
    }

    return (
        <Dialog
            scroll={"body"}
            PaperProps={{
                sx: {
                    overflow: "auto",
                    maxWidth: { md: "700px !important", xs: "100% !important" },
                    margin: { xs: 0 },
                    borderRadius: { xs: 0, md: 1 },
                    height: { xs: "100%", md: "auto" },
                    width: { xs: "100%" }
                }
            }}
            open={open}
            onClose={handleClose}
        >
            <Card>
                <CardContent>
                    <Box
                        sx={{
                            width: "100%",
                            top: 0,
                            left: 0,
                            bgcolor: "white",
                            position: { xs: "fixed", md: "relative" },
                            boxShadow: { xs: "0px -3px 10px #00000066", md: "none" },
                            padding: { xs: "16px 24px", md: 0 },
                            zIndex: 4
                        }}
                    >
                        <Stack direction={"row"} justifyContent={'end'}>                        
                            <CloseIcon onClick={handleClose} sx={{ width: 24, height: 24, color: "#000", cursor: "pointer" }} />
                        </Stack>
                    </Box>
                    <Stack mt={0} rowGap={2}>
                        <Box>
                        <Typography variant="body1" sx={{ fontWeight: 500}}>Transaction Summary</Typography>
                        <Box mt={1} ><Transactionsummary data={data} mt={1}/></Box>
                        </Box>
                        <AccInformation/>
                        <NoteComponent/>
                        <Box sx={{
                            width: "100%",
                            bottom: 0,
                            left: 0,
                            bgcolor: "white",
                            borderRadius: "15px 15px 0px 0px",
                            position: { xs: "fixed", md: "relative" },
                            boxShadow: { xs: "0px 3px 10px #00000066", md: "none" },
                            padding: { xs: "16px 24px", md: 0 },
                        }}>
                            <Box sx={{ px: { xs: 0, md: 8 } }}>
                                <Button size="small"  onClick={handleSubmit} disabled={submitting} fullWidth variant="contained" color="secondary" sx={{fontWeight:400,fontSize:'16px !important'}}>Confirm</Button>
                            </Box>
                        </Box>
                    </Stack>
                </CardContent>
            </Card>
        </Dialog>
    )
}



 function Transactionsummary({data}){
    const user = useSelector(state => state.user.data);
    const bankAccount = data?.accountSelected==="Personal"?"Personal":data?.accountSelected==="Trust"?"Trust":data?.accountSelected==="HUF"?"HUF":data?.username + "'s";
    return (
   
        <Stack spacing={"10px"} sx={{ borderRadius: 1, border: "1px solid #e1e1e1"}}>
            <Stack direction={"row"} justifyContent={"space-between"} alignItems={'center'} px={3} pt={1}  >
                <Typography variant="body2">Number of FRAX</Typography>
                <Typography variant="body2" sx={{ fontWeight: 500 }}>{convertToIndianFormat(data?.noOfFracs ,false) || "-"}</Typography>
            </Stack>
            <Divider />
            <Stack direction={"row"} justifyContent={"space-between"} alignItems={'center'} px={3} py={0}>
                <Typography variant="body2">Amount To be Paid Now</Typography>
                <Typography variant="body2" sx={{ fontWeight: 500 }}>{convertToIndianFormat(data?.amtTobePaid) || "-"}</Typography>
            </Stack>
            <Divider />
            {data?.pendingAmt !== 0 &&<><Stack direction={"row"} justifyContent={"space-between"} px={3}>
                <Typography variant="body2">Payment Later</Typography>
                <Typography variant="body2" sx={{ fontWeight: 500 }}>{convertToIndianFormat(data?.pendingAmt) || "-"}</Typography>
            </Stack>
            <Divider /></> }
            <Stack direction={"row"} justifyContent={"space-between"} px={3}>
                <Typography variant="body2">Total Investment Amount</Typography>
                <Typography variant="body2" sx={{ fontWeight: 500 }}>{convertToIndianFormat(data?.amtTobePaid + data?.pendingAmt) || "-"}</Typography>
            </Stack>
            {
                !data.rightToOwnerShip && <> <Divider />
                <Stack direction={"row"} justifyContent={"space-between"} px={3}>
                <Typography variant="body2"> DLT Account</Typography>
                <Typography variant="body2" sx={{ fontWeight: 500 }}>{`...${data?.dltAccount?.slice(-18)} - ${data?.dltNickname}`}</Typography>
                </Stack>
                </>
            }
            <Divider />
            {user?.isHufHead && <Stack direction={"row"} justifyContent={"space-between"} px={3}  pb={1}>
                <Typography variant="body2">Bank Account</Typography>
                <Typography variant="body2" sx={{ fontWeight: 500 }}>{bankAccount} Account</Typography>
            </Stack>}
        </Stack >
    )
 }
