import store from "../redux/store";
import { server } from "../utils/server";

const getTrustDetails = async (investorId)=>{
    return server.get(`/trust/auth/${investorId}`)
    .then((res)=>{
        return res.data
    })
    .catch((error)=>{
        return error
    })
   }

   const createTrust = async (data)=>{
    const config = { headers: {'Content-Type': 'multipart/form-data'}};
    return await server.post('/trust/auth/registration',data,config)
    .then((res)=>{
      return  res.data
    })
    .catch((err)=>{
        console.log(err);
        return err
    })
 }

 const getDltWallet=(id)=>{
    return server.get(`/trust/getDltWallet/${id}`)
    .then((res)=>{
        return res.data;
    })
    
}

 const getTrustById = async(id)=>{
        return server.get(`realXAdmin/trust/${id}`)
        .then((res)=>{
            return res.data
        })
        .catch((error)=>{
            return error
        })
    }

    const updateDLTCreationStatusTrust = (data) =>{
        return server.post("/trust/updateDLTCreationStatus",data)
        .then((res)=>{
            return res.data;
        })
        .catch((err)=>{
            console.log(err)
        });
    }
    const createBlockChainAcc = (data) =>{
        return server.post("trust/createWallet",data)
        .then(res=>{
            
            return res.data;
        })
        .catch(err=>{
            console.log(err);
            return null;
        })
    }
    const updatePrimaryDLTAccount = (data) =>{
        return server.post("/trust/updatePrimaryDLTAccount",data)
        .then((res)=>{
            return res.data;
        })
        .catch((err)=>{
            console.log(err)
        });
    }
    const removeDLTAccount = (data) =>{
        return server.post("/trust/removeDLTAccount",data)
        .then((res)=>{
            return res.data;
        })
        .catch((err)=>{
            console.log(err)
        });
    }

    const updateTrustDetails = async (_id,data)=>{
        const config = { headers: {'Content-Type': 'multipart/form-data'}};
        return server.put(`/trust/auth/update/${_id}`,data,config)
        .then((res)=>{
            return res.data
        })
        .catch((error)=>{
            return error
        })
       }

       const removeTrust =async(id,investorId)=>{
        return server.delete("/trust/auth/delete",{ data: { id: id, investorId: investorId }})
        .then((res)=>{
            return res.data;
        })
        .catch((err)=>{
            console.log(err)
        });
       }
   const trustServices = {
   
    getTrustDetails,
    createTrust,
    getDltWallet,
    getTrustById,
    updateDLTCreationStatusTrust,
    createBlockChainAcc,
    updatePrimaryDLTAccount,
    removeDLTAccount,
    updateTrustDetails,
    removeTrust
    
}

export default trustServices;