import { Box, Button, Card, CardContent, Container, Divider, Grid, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useFormik, Form, FormikProvider, getIn } from "formik";
import * as Yup from 'yup';
import FormFileField from '../../components/form/formFileField';
import FormField from '../../components/form/formField';
import bankServices from '../../services/bankServices';
import FormSelect from '../../components/form/formSelect';
import CustomDropZoneDoc from '../../components/customDropZoneDoc';
import AreYouHufHead from '../../components/modals/HufHeadAndTrust';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import userServices from '../../services/userServices';
import { useSnackbar } from 'notistack';
import trustServices from '../../services/trustServise';

const VALID_FILE_SIZE = 5000000;

const SUPPORTED_FORMATS = ["image/png", "image/jpeg"];

const SUPPORTED_FORMATS_PDF = ['application/pdf']

const trustKycSchema = Yup.object().shape({
  trustName:Yup.string()
  .required("Name is required.")
  .matches(/^[a-zA-Z\s.']+$/, "Enter a valid name")
  .min(3, "Name must be at least 3 characters"),
  panCardNumber: Yup.string().matches(/^[A-Za-z]{5}[0-9]{4}[A-Za-z]$/,"PAN No. must follow the PAN format")
    .length(10,"Pan No. must be exactly 10 characters").required("PAN No. is required."),
  bankInformation: Yup.object().shape({
    ifsc: Yup.string()
  .required("IFSC code is required.")
  .matches(/^[A-Za-z]{4}0[A-Za-z0-9]{6}$/i, "Enter a valid IFSC code"),
    accHolderName: Yup.string()
  .required("Name is required.")
  .matches(/^[a-zA-Z\s.']+$/, "Enter a valid name")
  .min(3, "Name must be at least 3 characters"),
    accNumber: Yup.string()
  .required("Account Number is required.")
  .matches(/^\d+$/, "Account Number must be digits only.")
  .min(6, "Account Number must be at least 6 digits.")
  .max(18, "Account Number must be at most 18 digits."),
    accType: Yup.string().required("Account Type is required."),
  }),

  panCard: Yup.mixed().nullable().required("Please upload this document.").test("FILE_SIZE", "Upload a file below 5MB.", value => value && value.size <= VALID_FILE_SIZE)
    .test(  "fileFormat",
      "Only JPEG and PNG files are allowed",
      (value) => !value || (value instanceof File && SUPPORTED_FORMATS.includes(value.type))),
  bankCheque: Yup.mixed().nullable().required("Please upload this document.").test("FILE_SIZE", "Upload a file below 5MB.", value => value && value.size <= VALID_FILE_SIZE)
    .test(  "fileFormat",
      "Only JPEG and PNG files are allowed",
      (value) => !value || (value instanceof File && SUPPORTED_FORMATS.includes(value.type))),
      trustDeed: Yup.mixed().nullable().required("Document Is Required").test("FILE_SIZE", "Uploaded file is too big.", value => value && value.size <= 4024 * 4024).test(  "fileFormat",
    "Only PDF files are allowed",
    (value) => !value || (value instanceof File && SUPPORTED_FORMATS_PDF.includes(value.type))),
})

const trustUpdateSchema = Yup.object().shape({
  panCardNumber: Yup.string().matches(/^[A-Z]{5}[0-9]{4}[A-Z]$/, "PAN No. must follow the PAN format")
    .length(10, "Pan No. must be exactly 10 characters").required("PAN No. is required."),
  bankInformation: Yup.object().shape({
    ifsc: Yup.string()
  .required("IFSC code is required.")
  .matches(/^[A-Za-z]{4}0[A-Za-z0-9]{6}$/i, "Enter a valid IFSC code"),
    accHolderName: Yup.string()
  .required("Name is required.")
  .matches(/^[a-zA-Z\s.']+$/, "Enter a valid name")
  .min(3, "Name must be at least 3 characters"),
    accNumber: Yup.string()
  .required("Account Number is required.")
  .matches(/^\d+$/, "Account Number must be digits only.")
  .min(6, "Account Number must be at least 6 digits.")
  .max(18, "Account Number must be at most 18 digits."),
    accType: Yup.string().required("Account Type is required."),
  }),
})

export default function TrustRegistration() {

  const [panSelectedImage, setPanSelectedImage] = useState(null);
  const [checkImage, setCheckImage] = useState(null);
  const [showBranch, setShowBranch] = useState("");
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar(); 
    const navigate=useNavigate();
    const user = useSelector(state => state.user.data);
  const { trustData,url,mode } = state || {};


  
 
  useEffect(() => {
    if (trustData && Object.keys(trustData).length > 0) {
      setPanSelectedImage(url + trustData?.huf?.documents?.find(doc => doc.docName === "Pan Card")?.docUrl);
      setCheckImage(url + trustData?.huf?.documents?.find(doc => doc.docName === "Bank Cheque")?.docUrl);
    }
  }, [trustData]);
  


  const formik = useFormik({
    initialValues: {
      trustName: trustData?.trust?.trustName || "",
      panCardNumber: trustData?.trust?.panCardNumber || "",
      panCard: trustData?.trust?.documents?.find(doc => doc.docName === "Pan Card")?.docUrl 
        ? { name: trustData?.trust?.documents?.find(doc => doc.docName === "Pan Card").docUrl } 
        : null,
      bankCheque: trustData?.trust?.documents?.find(doc => doc.docName === "Bank Cheque")?.docUrl 
        ? { name: trustData?.trust?.documents?.find(doc => doc.docName === "Bank Cheque").docUrl } 
        : null,
      bankInformation: {
        ifsc: trustData?.trust?.bankInformation?.ifsc || "",
        accHolderName: trustData?.trust?.bankInformation?.accHolderName || "",
        accNumber: trustData?.trust?.bankInformation?.accNumber || "",
        bank: trustData?.trust?.bankInformation?.bank || "",
        accType: trustData?.trust?.bankInformation?.accType || "",
      },
      trustDeed: trustData?.trust?.documents?.find(doc => doc.docName === "trust deed")?.docUrl 
        ? { name: trustData?.trust?.documents?.find(doc => doc.docName === "trust deed").docUrl } 
        : null,
    },
    validationSchema:trustData?.trust ? trustUpdateSchema : trustKycSchema,
    onSubmit: (data) => {
      console.log("fgyytyy")
      if(mode  === "edit"){
        const editedFields = Object.keys(data).reduce((acc, key) => {
          // Compare file fields by name (URL or new file) instead of entire object
          if (
            (key === "panCard" && data[key]?.name !== formik.initialValues[key]?.name) ||
            (key === "bankCheque" && data[key]?.name !== formik.initialValues[key]?.name) ||
            (key === "trustDeed" && data[key]?.name !== formik.initialValues[key]?.name) ||
            (key !== "panCard" && key !== "bankCheque" && key !== "trustDeed" && data[key] !== formik.initialValues[key])
          ) {
            acc[key] = data[key];
          }
          return acc;
        }, {});     
      handleUpdatetrustKyc(editedFields);
      }else{
        handletrustKyc(data);
      }
    },
  });
  const { errors, touched, handleSubmit, getFieldProps, values, setFieldValue, setFieldError, handleBlur, isSubmitting, setSubmitting } = formik;

  const accTypeOptions = [
    {
      title: "Current account",
      value: "Current account",
    },
    {
      title: "Saving account",
      value: "Saving account",
    },
  ]

  const handletrustKyc= async(data)=>{
    const formData=new FormData();
    Object.keys((data)).map((key)=>formData.append(key,data[key]));
    formData.delete('bankInformation');
    formData.append('bankInformation', JSON.stringify(data.bankInformation));
    formData.append("investorId",user._id);
    formData.append("isHead", true);
    formData.append("status","KYC_COMPLETE")

    const res =await trustServices.createTrust(formData);
    if(res && res.success) {
      navigate('/dashboard/profile?tab=3');
      enqueueSnackbar("trust details submitted",{
      variant:"success", anchorOrigin: { horizontal: "right", vertical: "top" },
      autoHideDuration: 3000,
      });
    }
    else{
      enqueueSnackbar("Something went wrong!", {
        variant: "error",
        anchorOrigin: { horizontal: "right", vertical: "top" },
        autoHideDuration: 3000,
      });
    }
  }

  const handleUpdatetrustKyc = async (data) => {
    const formData = new FormData();
    Object.keys(data).map((key) => formData.append(key, data[key]));
    formData.delete('bankInformation');
    formData.append('bankInformation', JSON.stringify(data.bankInformation));
    formData.append('investorId', user._id);
    formData.append("status","KYC_COMPLETE");

    const res = await trustServices.updateTrustDetails(trustData.trust._id, formData); 
    if (res && res.success) {
      navigate('/dashboard/profile?tab=3');
      enqueueSnackbar('trust details updated successfully', {
        variant: 'success',
        anchorOrigin: { horizontal: 'right', vertical: 'top' },
        autoHideDuration: 3000,
      });
    } else {
      enqueueSnackbar('Something went wrong while updating!', {
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'top' },
        autoHideDuration: 3000,
      });
    }
  };


  const getBankFromIFSC = async () => {
    
    if (values?.bankInformation?.ifsc) {
      const res = await bankServices.getBankFromIFSCCode(values?.bankInformation?.ifsc);
      if (res) {
        setShowBranch(`${res.BANK}, ${res.BRANCH}`);
        setFieldValue("bankInformation.bank", res.BANK)
        setFieldValue("bankInformation.branch", res.BRANCH)
      }
      else {
        setFieldError("bankInformation.ifsc", "IFSC Code Not Found");
        setShowBranch("Not Found");
      }
    }
  }
  

  const handleImageChange = (file, type) => {
    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        // Set the selected image to the uploaded image
       if (type === "checkImage") {
          setCheckImage(e.target.result);
        } else if (type === "panImage") {
          setPanSelectedImage(e.target.result);
        }
      };

      reader.readAsDataURL(file);
    } else {
      // If no file is selected, clear the selected image
      // setFrontSelectedImage(null);
    }
  };



  return (
    <Container>
      <Card sx={{ mt: 5 }}>
        <CardContent>
          <Stack>
            <Box><Typography variant='mainCardTitle'>{mode ==="edit"?'Update Trust Profile' :'Trust Registration'}</Typography>
              <Divider
                sx={{
                  width: '100%',
                  mt: 1,
                }}
              />
            </Box>
            <Box>
              <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate >
                  <Stack rowGap={{ md: 1.5, xs: 1 }}>
                    <Stack sx={{ mx: '-5px', mt: { md: 2, xs: 1 } }}>
                      <Grid container columnGap={{ md: 3, xs: 0 }} rowGap={{ md: 3, xs: 2 }} boxShadow={{ md: 1 }} sx={{ borderRadius: '3px', px: { md: 3, xs: 0 }, py: { md: 3, xs: 1 } }}>
                      <Grid item xs={12} md={3.8}>
                          <FormField
                            label={"Enter Trust Name *"}
                            inputProps={{
                              placeholder: "",
                              ...getFieldProps("trustName"),
                              error: Boolean(
                                touched.trustName && errors.trustName
                              ),
                              helperText: touched.trustName && errors.trustName,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} ><Typography>Upload Trust Documents</Typography></Grid>
                        <Grid item xs={12} md={3.8}>
                          <FormFileField
                            label={"Upload Trust PAN Card *"}
                            inputProps={{
                              error: Boolean(touched.panCard && errors.panCard),
                              helperText: touched.panCard && errors.panCard,
                              value: values.panCard,
                              onChange: (file) => {
                                setFieldValue("panCard", file)
                                handleImageChange(file, "panImage");
                              },
                            }}
                          />
                          {panSelectedImage && (
                            <Box
                              style={{
                                width: "auto",
                                height: "130px",
                                border: "1px solid rgb(183 183 183)",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: "6px",
                                marginTop: "8px",
                              }}>

                              <img
                                src={panSelectedImage}
                                alt="Selected Image"
                                style={{
                                  maxWidth: "100%",
                                  maxHeight: "100%",
                                  objectFit: "cover",
                                }}
                              />
                            </Box>
                          )}
                        </Grid>
                        <Grid item xs={12} md={3.8}>
                          <FormField
                            label={"Enter Trust PAN Card No *"}
                            inputProps={{
                              placeholder: "",
                              ...getFieldProps("panCardNumber"),
                              error: Boolean(
                                touched.panCardNumber && errors.panCardNumber
                              ),
                              helperText: touched.panCardNumber && errors.panCardNumber,
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Stack>
                    <Stack sx={{ mx: '-5px' }}>
                      <Grid container columnGap={{ md: 3.2, xs: 0 }} rowGap={{ md: 3, xs: 2 }} boxShadow={{ md: 1 }} sx={{ borderRadius: '3px', px: { md: 3, xs: 0 }, py: { md: 3, xs: 1 } }}>
                        <Grid item xs={12} ><Typography>Enter Trust Bank Details</Typography></Grid>
                        <Grid item xs={12} md={3.8}>
                          <FormField
                            label={"Account Holder Name *"}
                            inputProps={{
                              ...getFieldProps("bankInformation.accHolderName"),
                              error: Boolean(
                                touched.bankInformation?.accHolderName &&
                                errors.bankInformation?.accHolderName
                              ),
                              helperText:
                                touched.bankInformation?.accHolderName &&
                                errors.bankInformation?.accHolderName,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={3.8}>
                          <FormField
                            label={"IFSC CODE *"}
                            inputProps={{
                              ...getFieldProps("bankInformation.ifsc"),
                              onBlur: (e) => {
                                handleBlur(e);
                                getBankFromIFSC();
                              },
                              error: Boolean(
                                touched.bankInformation?.ifsc &&
                                errors.bankInformation?.ifsc
                              ),
                              helperText:
                                (touched.bankInformation?.ifsc &&
                                  errors.bankInformation?.ifsc) ||
                                showBranch,
                            }}
                          />

                        </Grid>
                        <Grid item xs={12} md={3.8}>
                          <FormField
                            label={"Account Number *"}
                            inputProps={{
                              ...getFieldProps("bankInformation.accNumber"),
                              onChange: (e) => {
                                if (RegExp("^[0-9]*$").test(e.target.value)) {
                                  setFieldValue(
                                    "bankInformation.accNumber",
                                    e.target.value
                                  );
                                }
                              },
                              error: Boolean(
                                touched.bankInformation?.accNumber &&
                                errors.bankInformation?.accNumber
                              ),
                              helperText:
                                (touched.bankInformation?.accNumber &&
                                  errors.bankInformation?.accNumber)
                            }}
                          />

                        </Grid>
                        <Grid item xs={12} md={3.8}>
                          <FormSelect
                            label={"Account Type *"}
                            options={accTypeOptions}
                            props={{
                              ...getFieldProps("bankInformation.accType"),
                              error: Boolean(
                                touched.bankInformation?.accType &&
                                errors.bankInformation?.accType
                              ),
                              helperText:
                                (touched.bankInformation?.accType &&
                                  errors.bankInformation?.accType)
                            }}
                          />

                        </Grid>

                        <Grid item xs={12} md={3.8}>
                          <FormFileField
                            label={"Cancelled Cheque Image*"}
                            inputProps={{
                              error: Boolean(touched.bankCheque && errors.bankCheque),
                              helperText: touched.bankCheque && errors.bankCheque,
                              value: values.bankCheque,
                              onChange: (file) =>{ 
                                setFieldValue("bankCheque", file);
                                handleImageChange(file, "checkImage");
                              },
                            }}
                          />
                        {checkImage && (
                          <Box
                          style={{
                            width: "auto",
                            height: "130px",
                            border: "1px solid rgb(183 183 183)",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "6px",
                            marginTop: "8px",
                          }}>

                              <img
                                src={checkImage}
                                alt="Selected Image"
                                style={{
                                  maxWidth: "100%",
                                  maxHeight: "100%",
                                  objectFit: "cover",
                                }}
                                />
                            </Box>
                          )}
                          </Grid>
                      </Grid>
                    </Stack>
                    <Stack mx={'-5px'}>
                      <Grid container alignItems={'center'} columnGap={{ md: 3, xs: 0 }} rowGap={{ md: 3, xs: 2 }} boxShadow={{ md: 1 }} sx={{ borderRadius: '3px', px: { md: 3, xs: 0 }, py: { md: 3, xs: 1 } }}>
                        <Grid item xs={12} md={3.8}>
                          <Box>
                            <CustomDropZoneDoc setter={(file) => { setFieldValue(`trustDeed`, file) }} title={'Trust Deed (max 15mb .pfd) *'} errors={getIn(touched, `trustDeed`) &&
                              getIn(errors, `trustDeed`)} files={values?.trustDeed} edit={true} />
                          </Box>
                        </Grid>
                        <Grid item xs={12} md={3.8}></Grid>
                      </Grid>

                    </Stack>

                  </Stack>
                  <Box sx={{ textAlign: 'center', mt: 4 }}>
                    <Button
                      type="submit"
                      variant="contained"
                      sx={{ width: { xs: "auto" } }}
                    >
                     {mode ==="edit"?'Update' :'Submit'}
                    </Button>
                    <Button
                      onClick={()=> navigate('/dashboard/profile')}
                      variant="outlined"
                      sx={{ width: { xs: "auto" }, ml: 2 }}
                    >
                      Cancel
                    </Button>
                  </Box>
                </Form>
              </FormikProvider>
            </Box>
          </Stack>

        </CardContent>
      </Card>
    </Container>
  )
}
