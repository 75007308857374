import AddCommentIcon from '@mui/icons-material/AddComment';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Menu,
  MenuItem,
  Skeleton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import CustomIcon from "../../../components/customIcon";
import AddFamilyMember from "../../../components/modals/addFamilyMember";
import HufHeadAndTrust from "../../../components/modals/HufHeadAndTrust";
import AreYouSureModal from "../../../components/modals/areYouSureModal";
import EditProfileModal from "../../../components/modals/editProfileModal";
import SecondaryBlockchainAccountModal from "../../../components/modals/secondaryBlockchainAccount";
import Tabs from "../../../components/tabs/tabs";
import { saveUser } from "../../../redux/reducers/userSlice";
import store from "../../../redux/store";
import authServices from "../../../services/authServices";
import familymemberServices from "../../../services/familymemberServices";
import userServices from "../../../services/userServices";
import FamilyMemberProfile from "./FamilyMember/familymemberProfile";
import HufProfile from "./Huf/HufProfile";
import trustServices from '../../../services/trustServise';
import TrustProfile from './Trust/TrustProfile';


export default function MyProfile() {

  const location = useLocation();

  
  const investorId = store.getState().user.data._id;
  const [hufHeadDetails,setHufHeadDetails] =useState([]);
  const [trustDetails,setTrustDetails] =useState([]);
  const [familyMemberDetail,setFamilymemberDetail]=useState([]);
  const [url,setUrl] = useState("")
  const getHufDetails = async () =>{
    try{
      const res= await userServices.getHufDetails(investorId)         
      if(res && res.success){
        setHufHeadDetails(res.data)
        setUrl(res.url)
      }  
    }       
    catch(error){
      console.log('Error fetching HUF details:',error);         
    }
  }

  const getTrustDetails = async () =>{
    try{
      const res= await trustServices.getTrustDetails(investorId)         
      if(res && res.success){
        setTrustDetails(res.data)
        setUrl(res.url)
      }  
    }       
    catch(error){
      console.log('Error fetching Trust details:',error);         
    }
  }

  const getallFamilyMember = async () =>{
    try{
      const res= await familymemberServices.familyMemberAccordingToInvester(investorId)        
      if(res && res.success){
     
        setFamilymemberDetail(res.data)
        // setUrl(res.url)
      }  
    }       
    catch(error){
      console.log('Error fetching HUF details:',error);         
    }
  }


    useEffect(()=>{
      getallFamilyMember()
      getHufDetails();
      getTrustDetails();
    },[investorId])

console.log(trustDetails)

  const tabs = [
    {
        name: "Personal Profile",
        tab: <PersonalProfile hufHeadDetails={hufHeadDetails} familyMemberDetail={familyMemberDetail} getallFamilyMember={getallFamilyMember} trustDetails={trustDetails} />
    },
    ...(hufHeadDetails.length > 0
       ?[
          {
            name: "HUF Profile",
            tab: <HufProfile hufHeadDetails={hufHeadDetails} url={url} getHufDetails={()=>getHufDetails()} />,
          },
        ]
       :[]),
       ...(familyMemberDetail.length > 0
        ?[
           {
             name: "Family Member",
             tab: <FamilyMemberProfile fmailyMemberDetail={familyMemberDetail} url={url} getallFamilyMember={getallFamilyMember} />,
           },
         ]
        :[]),
        ...(trustDetails.length > 0
          ?[
             {
               name: "Trust",
               tab: <TrustProfile trustDetails={trustDetails} url={url} getTrustDetails={()=>getTrustDetails()} />,
             },
           ]
          :[]),
];


  

const [activeTab, setActiveTab] = useState(0);

const [showSignUp, setShowSignUp] = useState(false);

const handleTabClick = (index) => {
    setActiveTab(index);
}

useEffect(()=>{
  const params= new URLSearchParams(location.search);
  const tab= params.get('tab')
  
  if(tab && tabs[parseInt(tab, 10)]){
    setActiveTab(parseInt(tab, 10))
  }

},[hufHeadDetails])

useEffect(()=>{
  // const params= new URLSearchParams(location.search);
  // const tab= params.get('tab')
  
  

},[hufHeadDetails])

const customHandleClose = () => {
  setShowSignUp(!showSignUp);
}
  
  return (
     <Card sx={{mt:{md:2,xs:1}}}>
     <CardContent>
         {/* <Stack spacing={"5px"}> */}
             {/* <Stack direction={"row"} spacing={{ md: 3, xs: 1.5 }} alignItems={"center"} >
                 <Box sx={{
                     width: { md: 48, xs: 32 }, height: { md: 48, xs: 32 },
                     bgcolor: "primary.light",
                     borderRadius: "100%",
                     display: "flex",
                     justifyContent: "center",
                     alignItems: "center"
                 }}>
                     <CustomIcon name="media-live" sx={{ bgcolor: "primary.main", width: { md: 24, xs: 16 }, height: { md: 24, xs: 16 } }} />
                 </Box>
                 <Typography variant="mainCardTitle" sx={{ flex: 1 }}>Properties Listings</Typography>
             </Stack> */}
         {/* </Stack> */}
         <Stack direction={"row"} justifyContent={"space-between"}
             sx={{
                 pb: "10px",
                 pl: { md: 0, xs: 0 },
             }} >
             <Tabs activeTab={activeTab} tabs={tabs} onClick={handleTabClick} />
           
         </Stack>
         <Divider sx={{
             width: "calc(100% + 80px)",
             mx: -5
         }} />
         <Grid container columnGap={2} rowSpacing={1} mt={{ md: 1 , xs: 1 }} sx={{ backgroundColor: "#f7f7f7", paddingBottom: 3,flexDirection:"column"}}>
             {
                 tabs[activeTab]?.tab
             }
             <Box sx={{ marginLeft:"10px"}}>
            
            </Box>
         </Grid>
         <Divider sx={{
             width: "calc(100% + 80px)",
             mx: -5,
             marginTop:"10px",
             marginBottom:"10px"
         }} />
        
         
             
            
           
  
     </CardContent>
 </Card>

    
  )
}

function PersonalProfile ({hufHeadDetails,familyMemberDetail,getallFamilyMember,trustDetails}) {

  const user = useSelector((state) => state.user.data);
  const [isEditing, setIsEditing] = useState(false);
  const [isBankEditing, setIsBankEditing] = useState(false);
   const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  

  const dispatch = useDispatch();

  const [availableBlockchainAccounts, setAvailableBlockchainAccounts] = useState([]);
  const [blockchainAccountModal, setBlockchainAccountModal] = useState(false);
  const [usernameError, setUsernameError] = useState(false);
  const [showTrustQues,setShowTrustQues]=useState(false)
  const [showHufQues,setShowHufQues] = useState(false);
  const [createFamilyMember,setCreateFamilyMember]=useState(false)
  const [showEditProfile,setShowEditProfile] =useState(false);
  

  useEffect(() => {
    fetchDltWallet()
  }, [user])

  const fetchDltWallet = async () => {
    let dltWallet = await userServices.getDltWallet(user._id)
    setAvailableBlockchainAccounts(dltWallet.data)
  }

  const handleHufClose =()=>{
    setShowHufQues(false);
  }
  const handleTrustClose =()=>{
    setShowTrustQues(false);
  }
  const handleCreateFamilyMember=()=>{
    setCreateFamilyMember(false)
  }

  



  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const TypographyText = ({ label, value,isRow=false}) => {
    return (
      <Stack direction={isRow ? "row" :{md:"column",xs:"row"}} spacing={1} justifyContent={isRow ? "space-between": {xs:"space-between",md:"start"}}
        sx={{
          width: "100%", 
          wordWrap: "break-word", 
          overflowWrap: "break-word", 
        }}>
        <Typography variant="label">
        {label}
        </Typography>
        <Typography
          variant="body3"
          sx={{         
            wordWrap: "break-word",
            overflowWrap: "break-word", 
            whiteSpace: "normal", 
            overflow: "hidden", 
          }}
        >
         {value}
        </Typography>
      </Stack>
    );
  };

  const handleProfileClose = ()=>{
    setShowEditProfile(false)
  }
  
  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
};

  return(
    <>
      
    <EditProfileModal user={user} open={showEditProfile} handleClose={handleProfileClose} dispatch={dispatch}/>
    <HufHeadAndTrust open={showHufQues} handleClose={handleHufClose} />
    <HufHeadAndTrust open={showTrustQues} handleClose={handleTrustClose} />
    <AddFamilyMember open={createFamilyMember}handleClose={handleCreateFamilyMember} getallFamilyMember={getallFamilyMember}/>
    <Stack
        width={{md:'80%',xs:'100%'}}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        my={2}
        mx={{md:"auto",xs:0}}
        px={{md:'40px',xs:'5px'}}
      >
        <Typography variant="mainCardTitle">
          My Profile
        </Typography>
        

        <Stack direction={'row'} columnGap={1}>
          {/* {user?.status === "APPROVED" && user?.citizenShip === "INDIAN" && hufHeadDetails.length === 0 && <Button variant="outlined" size="small" onClick={() => setShowHufQues(true)}>Create an HUF</Button>}
          {user?.status === "APPROVED" && user?.citizenShip === "INDIAN" && familyMemberDetail.length === 0 && <Button variant="outlined" size="small" onClick={() => setCreateFamilyMember(true)}>Add Family member</Button>} */}
          <Stack direction="row" spacing={2}>
            
          </Stack>
         
          <Button
            variant="outlined"
            color="primary"
            size="small"
            sx={{ padding: '10px 12px !important' }}
            onClick={() => { setShowEditProfile(true) }}
          >
            Edit Profile
          </Button>
          <EntityMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} getallFamilyMember={getallFamilyMember} familyMemberDetail={familyMemberDetail} hufHeadDetails={hufHeadDetails} trustDetails={trustDetails}/>
          {user?.status === "APPROVED" && user?.citizenShip === "INDIAN" && <Button variant="contained" size="small" sx={{cursor:"pointer"}}  startIcon={<AddCommentIcon />} onClick={openMenu}>Add Entity</Button>}
        </Stack>
      </Stack>
  
      <SecondaryBlockchainAccountModal open={blockchainAccountModal} setOpen={setBlockchainAccountModal} user={user} dispatch={dispatch} />
     
      
        {user?(
          <Stack spacing={2}  sx={{ m: '0px auto',width:{sm:'80%',xs:'100%'}}}>
          <Card sx={{ m: '12px auto'}}> 
          <CardContent sx={{p:'0px !important'}}> 
              <Stack direction={{md:'row',xs:'column'}} spacing={2}>
                <Stack sx={{ width:{md:'100%',xs:'100%'},padding: {md:"30px",xs:"16px"}, borderRadius: "5px"}}>
                  <Stack rowGap={{md:0,xs:0}}>
                    <Box>
                    <Typography variant="headerMenu">Personal Info</Typography>
                    </Box>
                    <Divider
                              sx={{
                                width: '100%',
                                my: { md: 2, xs: 1 }
                               
                              }}
                            /> 
                    <Stack rowGap={{md:4,xs:'4px'}}>
                      <Stack direction={{md: 'row', xs: 'column'}} rowGap={{xs:'4px',md:0}} columnGap={4}>
                        <TypographyText label="User Name" value={user.username} />
                        <TypographyText label="legal Name" value={user?.legalName} />
                        <TypographyText label="Mobile Number" value={user.mobileNumber} />
                      </Stack>
                      <Stack direction={{ md: 'row', xs: 'column' }} rowGap={{xs:'4px',md:0}} columnGap={4}>
                        <TypographyText label="Email" value={user.emailId} />
                        <TypographyText label="Date Of Birth" value={moment.unix(user.dob).format('DD-MM-YYYY')} />
                        <TypographyText label="Citizenship" value={user.citizenShip} />
                      </Stack>
                      {/* <Stack direction={{ md: 'row', xs: 'column' }}>
                        <TypographyText label="Gender" value={user.gender} />
                      </Stack> */}
                    </Stack>
                  </Stack>                 
                </Stack>
              </Stack>  
              </CardContent>
              </Card>
          <Card sx={{ m: '12px auto'}}> 
          <CardContent sx={{p:'0px !important'}}> 
          <Stack sx={{ padding: {md:"30px",xs:"16px"}, borderRadius: "5px"}}>
            <Typography variant="headerMenu">Address Info</Typography> 
                <Divider
                  sx={{
                    width: '100%',
                    my: { md: 2, xs: 1 }
                  }}
                />  
            <Stack direction={{md:"row",xs:"column"}} columnGap={6}  >
              <Stack sx={{width:{md:'50%'}}}  >
                <Typography variant="headerTab" sx={{ fontWeight: "400",mb:{md:2,xs:1}}}>
                  Correspondence Address
                </Typography>
                <Stack rowGap={{ md: 2, xs: '4px' }}>
                  <Stack direction={{ xs: 'column' }}  rowGap={{ xs: '4px', md: 2}}>
                    <TypographyText label="Flat No,House No" value={user?.userAddress?.flatNo} isRow={true} />
                    <TypographyText label="Area,Street" value={user?.userAddress?.area} isRow={true} />
                    <TypographyText label="Landmark" value={user?.userAddress?.landmark} isRow={true} />
                  </Stack>
                  <Stack direction={{ xs: 'column' }} rowGap={{ xs: '4px', md: 2}}>
                    <TypographyText label="Town/City" value={user?.userAddress?.town} isRow={true} />
                    <TypographyText label="State" value={user?.userAddress?.state} isRow={true} />
                    <TypographyText label="Pincode" value={user?.userAddress?.pincode} isRow={true} />
                  </Stack>
                </Stack>

              </Stack>
              <Divider orientation="vertical" flexItem sx={{ borderColor: '#F7F7F7',borderWidth:'8px' }} />
              <Stack sx={{width:{md:'50%'}}}>
                <Typography variant="headerTab" sx={{mb:{md:2,xs:1}}}>
                  Aadhar Address
                </Typography>
                
                <Stack rowGap={3}>
                  <Stack rowGap={{ xs: '4px', md: 2 }}>
                    <Stack direction={{  xs: 'column' }} rowGap={{ xs: '4px', md: 2 }} columnGap={4}>
                      <TypographyText label="Flat No,House No" value={user?.aadharAddress?.flatNo || "-"} isRow={true} />
                      <TypographyText label="Area,Street" value={user?.aadharAddress?.area || "-"} isRow={true} />
                      <TypographyText label="Landmark" value={user?.aadharAddress?.landmark || "-"}  isRow={true} />
                    </Stack>
                    <Stack direction={{  xs: 'column' }} rowGap={{ xs: '4px', md: 2 }} columnGap={4}>
                      <TypographyText label="Town/City" value={user?.aadharAddress?.town || "-"} isRow={true} />
                      <TypographyText label="State" value={user?.aadharAddress?.state || "-"} isRow={true} />
                      <TypographyText label="Pincode" value={user?.aadharAddress?.pincode || "-"} isRow={true} />
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>   
          </Stack>
          </CardContent>
              </Card>
            
          <Card sx={{ m: '12px auto' }}>
            <CardContent sx={{ p: '0px !important' }}>
              <Stack direction={{ md: 'row', xs: 'column' }} spacing={2}>
                <Stack sx={{ width: { md: '100%', xs: '100%' }, padding: { md: "30px",xs:"16px" }, borderRadius: "5px" }}>
                  <Stack  >
                    <Box>
                      <Typography variant="headerMenu">Bank Info</Typography>
                    </Box>
                    <Divider
                      sx={{
                        width: '100%',
                        my: { md: 2, xs: 1 }
                      }}
                    />
                    <Stack rowGap={{ md: 4, xs: '4px' }}>
                      <Stack direction={{ md: 'row', xs: 'column' }} rowGap={{ xs: '4px', md: 0 }} columnGap={4}>
                        <TypographyText label="Account Number" value={user.bankInformation?.accNumber} />
                        <TypographyText label="IFSC Code" value={user?.bankInformation?.ifsc} />
                        <TypographyText />
                      </Stack>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            </CardContent>
          </Card>
          <Card sx={{ m: '12px auto' }}>
            <CardContent sx={{ p: '0px !important' }}>
              {user?.status === "APPROVED" && <Box sx={{ padding: { md: "30px",xs:"10px" }, borderRadius: "5px"}}>

                <BlockchainSection
                  availableBlockchainAccounts={availableBlockchainAccounts}
                  setBlockchainAccountModal={setBlockchainAccountModal}
                  user={user}
                  dispatch={dispatch}
                />
              </Box>}
            </CardContent>
          </Card>
            </Stack>)
            :
            (<><Box marginTop={"-110px"}>
              <Skeleton width={"100%"} height={400} />
            </Box>
              <Box marginTop={"-110px"}>
                <Skeleton width={"100%"} height={400} />
              </Box>
              <Box marginTop={"-110px"}>
                <Skeleton width={"100%"} height={400} />
              </Box>
            </>)}
         
   </>

  )

}

function BlockchainSection({ availableBlockchainAccounts, user, dispatch, setBlockchainAccountModal }) {

  const { enqueueSnackbar } = useSnackbar();

  const [currentIndex, setCurrentIndex] = useState(0);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [primaryOpen, setPrimaryOpen] = useState(false);
  const [dltAccountId, setDltAccountId] = useState(); 
  const handlePrimary = async () => {
    const res = await userServices.updatePrimaryDLTAccount({ entityId: user?._id, dltWalletId: dltAccountId ,isSection:"invester"});
    if (res && res.success) {
      dispatch(saveUser(res.data));
    }
  }

  const handleRemove = async () => {
    const res = await userServices.removeDLTAccount({ entityId: user?._id, dltWalletId: dltAccountId ,isSection:"invester"});
    if (res && res.success) {
      dispatch(saveUser(res.data));
      enqueueSnackbar("DLT Account Deleted Successfully!", { variant: "success", anchorOrigin: { horizontal: "right", vertical: "top" }, autoHideDuration: 3000 });
    } else enqueueSnackbar("This DLT Account is Holding Some Digital Assets in it. Please Contact RealX Admin for More Information!", { variant: "error", anchorOrigin: { horizontal: "center", vertical: "bottom" }, autoHideDuration: 9000 });
  }

  const handleDLTCreationStatus = async (investorId, status) => {
    const res = await userServices.updateDLTCreationStatus({ investorId, status });
    if (res && res.success) {
      dispatch(saveUser(res.data));
    }
  }

  const copyUrl = (account) => {
    navigator.clipboard.writeText(account);
    enqueueSnackbar("Copied to Clickboard", { variant: "success", anchorOrigin: { horizontal: "right", vertical: "top" }, autoHideDuration: 800 })

  };


  return (
    <Stack spacing={3}>
      <AreYouSureModal open={primaryOpen} setOpen={setPrimaryOpen} handleYes={handlePrimary} message={"Are You Sure About Updating your Primary DLT Account?"} publicAddress={availableBlockchainAccounts[currentIndex]?.publicAddress} publicAddressMessage={"Your New Primary DLT Account Address"} />
      <AreYouSureModal open={deleteOpen} setOpen={setDeleteOpen} handleYes={handleRemove} message={"Are You Sure About Permanently Deleting this DLT Account?"} note={"This action can not be undo!"} publicAddress={availableBlockchainAccounts[currentIndex]?.publicAddress} publicAddressMessage={"DLT Account Address To Be Removed"} />

      <Typography variant="headerMenu">Available Blockchain Accounts</Typography>

      <Stack flexDirection={"column"}  sx={{ overflowX: { xs: 'auto', sm: 'auto', md: 'hidden' },flexWrap:"wrap" }}>

        {(availableBlockchainAccounts[0] !== undefined && availableBlockchainAccounts.length >= 1) ? (
          availableBlockchainAccounts
          .sort((a, b) => (b.isPrimary ? 1 : -1) - (a.isPrimary ? 1 : -1))
          .map((account, index) => {

            return (
              <>

                <Stack flexDirection={"row"} columnGap={{ xs: 1, sm: 0 }} sx={{ width: { lg: "60%", md: "65%", sm: "85%", xs: "100%" } }}  >
                  <Stack flexDirection={"row"}sx={{padding: "10px", borderRadius: "10px", boxShadow:1, margin: "5px", width:"100%"}}>
                    <Box sx={{ padding: "2px" }}>
                    <Box sx={{ display: "flex",flexDirection:{md:'row',xs:'column'}, alignItems:'center' }}> <Typography>#{index + 1}. ...{account.publicAddress?.slice(-20)}</Typography>
                      <Box onClick={(e) => { e.stopPropagation(); e.preventDefault(); copyUrl(account.publicAddress);}} sx={{ bgcolor: '#f4f6f7', padding: '7px', borderRadius: '50%', marginLeft: '10px',hight:"20px",width:"30px" }}>
                        <CustomIcon
                          name="copy"
                          sx={{ bgcolor: "#FFF", backgroundColor: "#333333", cursor: "pointer" }}
                        />
                      </Box>
                      <Box sx={{ marginLeft: "4px" }}>
                          {account.isPrimary ? (
                            <>
                              <Typography sx={{ color: "white", background: "#21ab37", padding: "2px 15px !important", fontSize: "12px !important", border: "1px solid #21ab37", borderRadius: "10px" }}>Primary Account</Typography>

                            </>
                          )
                            : <Stack flexDirection={"row"} alignItems={'center'} columnGap={{ xs: 1, sm: 2 }} sx={{ m: "0 !important" }}>
                              <Button
                                variant="contained"
                                sx={{ p: "4px 15px !important", fontSize: "12px !important", marginLeft: '5px' }}
                                onClick={() => { setCurrentIndex(index); setPrimaryOpen(true);setDltAccountId(account._id) }}
                              >Make Primary</Button>
                              <RemoveCircleOutlineIcon sx={{ color: "red", cursor: "pointer" }} onClick={() => { setCurrentIndex(index); setDeleteOpen(true);setDltAccountId(account._id) }} />

                            </Stack>}
                      </Box>
                    </Box>
                      
                      <Box sx={{ display: "flex", flexDirection:"row",paddingTop:"10px" }}>
                        <Box sx={{mr:1}}>
                          <Typography sx={{fontWeight:"600"}}>{account?.name?.slice(0, 30)}</Typography>
                        </Box>
                       <Box>
                       <Typography variant="label" >{account?.created_at ? ` Created On: ${moment(account?.created_at).format("DD-MM-YYYY")}` : ""}</Typography>
                       </Box>
                      </Box>
                      
                    </Box>
                  </Stack>

                </Stack>

              </>
            )
          })
        ) :
          (
            <>
              <Typography variant="subtitle1">You have not made any DLT accounts yet</Typography>
            </>
          )
        }

      </Stack>

      <Stack flexDirection={"row"} gap={1}>
        <Button
          onClick={() => user?.walletInfo?.isAllowedToCreateDLT == "APPROVED" ? setBlockchainAccountModal(true) : handleDLTCreationStatus(user?._id, "PENDING")}
          variant="contained"
          sx={{ width: "max-content", py: "10px !important" }}
          disabled={availableBlockchainAccounts?.length > 2 || user?.walletInfo?.isAllowedToCreateDLT == "PENDING"}
        >
          {user?.walletInfo?.isAllowedToCreateDLT == "APPROVED" ? "Create a New DLT Account" :
            user?.walletInfo?.isAllowedToCreateDLT == "PENDING" ? "Request Submitted to RealX Admin" : "Request to Create a New DLT Account"}
        </Button>

        {user?.walletInfo?.isAllowedToCreateDLT == "PENDING" && <Button
          variant="contained"
          sx={{ py: "7px !important" }}
          onClick={() => handleDLTCreationStatus(user?._id, "CREATED")}
        >
          Cancel Request
        </Button>}

      </Stack>

    </Stack>
  )
}


const EntityMenu = ({anchorEl,setAnchorEl,getallFamilyMember,hufHeadDetails,familyMemberDetail,trustDetails}) =>{
  const [showHufQues,setShowHufQues] = useState(false);
  const [showTrustQues,setShowTrustQues]=useState(false);
  const [createFamilyMember,setCreateFamilyMember]=useState(false)
  const [huforTrust,setHuforTrust]=useState(false)

  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  
  const handleClose = () => {
      setAnchorEl(null);
  };


  const handleHufClose =()=>{
    setShowHufQues(false);
  }
  const handleTrustClose =()=>{
    setShowTrustQues(false);
  }

  const handleCreateFamilyMember=()=>{
    setCreateFamilyMember(false)
  }


  return(
      <>
     <HufHeadAndTrust hufAndTurst={huforTrust} open={showHufQues} handleClose={handleHufClose} />
     <HufHeadAndTrust hufAndTurst={huforTrust} open={showTrustQues} handleClose={handleTrustClose} />
     <AddFamilyMember open={createFamilyMember} handleClose={handleCreateFamilyMember} getallFamilyMember={getallFamilyMember}/>
      <Menu
      PaperProps={{
          sx:{
              minWidth:"fit-content !important",
              bgcolor:' #bdbdbd mportant',
              color:'#111',              
          }
      }}
      sx={{
        transform: "translateX(30px)"       
      }}
      anchorEl={anchorEl}
      anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
    >
      {hufHeadDetails.length < 1 && <MenuItem onClick={handleClose}>
          <Stack direction={"row"} spacing={1.5}>
              <Typography variant="body2" onClick={() => {setShowHufQues(true);setHuforTrust(true)}}>Create an Huf</Typography>
          </Stack>
      </MenuItem>}
      {familyMemberDetail.length < 2 && <MenuItem onClick={handleClose}>
          <Stack direction={"row"} spacing={1.5}>
              <Typography variant="body2" onClick={() => setCreateFamilyMember(true)}>Add Family Member</Typography>
          </Stack>
      </MenuItem>}
      {trustDetails.length < 1 &&<MenuItem onClick={handleClose}>
          <Stack direction={"row"} spacing={1.5}>
              <Typography variant="body2" onClick={() => {setShowTrustQues(true);setHuforTrust(false)}}>Add Trust</Typography>
          </Stack>
      </MenuItem>}
    </Menu>
    </>
  )
}