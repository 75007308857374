import { useSnackbar } from "notistack";
import { useState } from "react";
import { Box, Stack, Typography, Button, Checkbox, CircularProgress } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import AreYouSureModal from "../../components/modals/areYouSureModal";
import CustomIcon from "../../components/customIcon";
import FormField from "../../components/form/formField";
import authServices from "../../services/authServices";
import familymemberServices from "../../services/familymemberServices";
import userServices from "../../services/userServices";

const BlockchainAcc = ({ user, dispatch, customHandleClose, setAnimating, sendMail, setSendMail, mnemonic, setmnemonic, setBlockchainAccountDownload, setBlockchainAccountCreated, setEncryptedMnemonic, dltName, setDltName,setUser ,familyMemberId,sendToFamilyMember,setSendToFamilyMember,getallFamilyMember,publicAddress,setPublicAddress,blockchainAccountCreated}) => {

    const [loading, setLoading] = useState(false);
    const [keyDownloadButtonTxt, setKeyDownloadButtonTxt] = useState('Download Encrypted Key')
    const [confirmModal, setConfirmModal] = useState(false)
    const enqueueSnackbar = useSnackbar();
    const createBlockChainAcc = async () => {
        if (dltName === "") {
            setConfirmModal(true)
            return;
        }
        await saveDlt()
    }

    const copyText = (text) => {
        navigator.clipboard.writeText(text);
        enqueueSnackbar("Copied to clipboard.", { variant: "success" })
    }

    const downloadTxtFile = async () => {
        // eslint-disable-next-line
        const element = document.createElement("a");
        // eslint-disable-next-line
        const res = await authServices.encryptCredentials({ mnemonic });
        if (res && res.success) {
            setEncryptedMnemonic(res.data);
            const file = new Blob([publicAddress + "|" + res.data], { type: 'text/plain' });
            element.href = URL.createObjectURL(file);
            // fix realtion undefind
            element.download = `ENCRYPTED_KEY_FAMILY_${dltName}.txt`;
            // document.body.appendChild(element); // Required for this to work in FireFox
            element.click();
           
            setBlockchainAccountDownload(true);
            getallFamilyMember()
        }
        else {
            enqueueSnackbar("Something Went Wrong, Please Try Again!", { variant: "error" });
        }
        setKeyDownloadButtonTxt('Encrypted Key Downloaded')
    }

    const saveAndDownloadDlt = async () => {
        let data = {
            "isSection":"familyMember",
            "entityId": familyMemberId,
            "dltName": dltName,
            "publicAddress":publicAddress
        }
        setLoading(true);
        let res = await userServices.downloadPublicAddressAndMemonic(data);
        setLoading(false);
        if (res && res.success) {
            downloadTxtFile()
        }
        else {
            enqueueSnackbar("Something went wrong!!!", { variant: "error" });
        }
    }
    const saveDlt = async () => {
        let data = {
            "isSection":"familyMember",
            "entityId": familyMemberId,
        }
        setLoading(true);
        let res = await userServices.createPublicAddressAndMemonic(data);
        setLoading(false);
        if (res && res.success) {
            console.log(res,res?.publicAddress)
            setPublicAddress(res?.publicAddress)
            setUser(res.data)
            setAnimating(true);
            setmnemonic(res?.mnemonic);
            setBlockchainAccountCreated(true);

        }
        else {
            enqueueSnackbar("Something went wrong!!!", { variant: "error" });
        }
    }


    return (
        <Box mt={4}>
            <AreYouSureModal message={"Do you want to save without wallet name?"} setOpen={setConfirmModal} open={confirmModal} handleYes={() => { saveDlt() }} />
            {
                (blockchainAccountCreated === true) ?
                    <Stack alignItems={"center"} mt={{ md: 8 }} spacing={{ md: 6, xs: 3 }}>
                        <Box
                            sx={{
                                width: { md: 112, xs: 88 }, height: { md: 112, xs: 88 },
                                bgcolor: "success.light",
                                borderRadius: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                mt: { md: 0, xs: 3 }
                            }}
                        >
                            <CustomIcon name="check" sx={{ bgcolor: "success.main", width: { md: 50, xs: 32 }, height: { md: 50, xs: 32 }, }} />
                        </Box>
                        <Stack spacing={3} width={"100%"} alignItems={{ md: "center" }}>
                            <Stack alignItems={"center"} spacing={1} sx={{ maxWidth: 408 }} textAlign={"center"}>
                                <Typography variant="h4">Digital Assets Account Created</Typography>
                                <Typography variant="subtitle1" sx={{ color: "#000", fontWeight: 400 }}>
                                    Your digital assets account is successfully created.
                                    All the tokens that you subscribed to will be credited to this account.
                                    <i><b> PLEASE SAVE AND KEEP SECURE THE BELOW PRIVATE KEY (PASSWORD) OF YOUR BLOCKCHAIN ACCOUNT.</b></i></Typography>
                            </Stack>
                            <Box px={{ md: 2 }}>
                                <Box
                                    sx={{
                                        p: 3,
                                        borderRadius: "6px",
                                        border: "1px solid #E1E1E1",
                                    }}
                                >
                                    <Stack spacing={1.5}>
                                        <Stack spacing={0.5}>
                                            <Typography variant="subtitle1">Account ID  (Public Key)</Typography>
                                            <Stack direction={"row"} px={3} py={1.5} justifyContent={"space-between"} sx={{ border: "1px solid #E1E1E1", borderRadius: 1 }}>
                                                <Typography variant="body2" sx={{ textOverflow: "ellipsis", width: { md: 256, xs: "100%" }, whiteSpace: "nowrap", overflow: "hidden" }}>{publicAddress}</Typography>
                                                <Box onClick={() => copyText(publicAddress)} sx={{ cursor: "pointer", }}>
                                                    <CustomIcon name="copy" />
                                                </Box>
                                            </Stack>
                                        </Stack>
                                        <Stack spacing={0.5}>
                                            <Typography variant="subtitle1">Password (Private Key)</Typography>
                                            <Stack spacing={0.5} direction={"row"} px={3} py={1.5} justifyContent={"space-between"} sx={{ border: "1px solid #E1E1E1", borderRadius: 1 }}>
                                                <Typography variant="body2" sx={{ textOverflow: "ellipsis", width: { md: 256, xs: "100%" }, whiteSpace: "nowrap", overflow: "hidden" }}>{mnemonic}</Typography>
                                                <Box onClick={() => copyText(mnemonic)} sx={{ cursor: "pointer", }}>
                                                    <CustomIcon name="copy" />
                                                </Box>
                                            </Stack>
                                        </Stack>
                                        <Button onClick={() => {saveAndDownloadDlt()}} variant="contained" sx={{ mb: 1 }}>{keyDownloadButtonTxt}</Button>
                                    </Stack>
                                </Box>
                            </Box>

                            <Box sx={{
                                maxWidth: { md: 408, xs: "100%" },
                                bgcolor: "warning.light",
                                px: "10px",
                                py: 1,
                                borderRadius: 0.5
                            }}>
                                <Stack spacing={1}>
                                    <Stack direction={'row'} rowGap={2}>
                                        <Checkbox
                                            checked={sendMail}
                                            onClick={() => setSendMail(!sendMail)}
                                            color="primary"
                                        />
                                        <Typography variant="subtitle2" sx={{ color: "#000", fontWeight: 400 }}>Send key detail file to my email. Keep this file secure as it can be used to access DLT account.</Typography>
                                    </Stack>
                                    <Stack direction={'row'} rowGap={2}>
                                        <Checkbox
                                            checked={sendToFamilyMember}
                                            onClick={() => setSendToFamilyMember(!sendToFamilyMember)}
                                            color="primary"
                                            defaultChecked={false}
                                        />
                                        <Typography variant="subtitle2" sx={{ color: "#000", fontWeight: 400 }}>Send key detail file to family member's email. Keep this file secure as it can be used to access DLT account.</Typography>
                                    </Stack>
                                </Stack>
                            </Box>
                            <Stack px={{ md: 6 }}>
                                <Button onClick={customHandleClose} variant="outlined" sx={{ mb: 1 }}>Complete Account Creation</Button>
                            </Stack>
                            <Box
                                sx={{
                                    maxWidth: 408,
                                    bgcolor: "warning.light",
                                    px: "10px",
                                    py: 1,
                                    borderRadius: 0.5,
                                }}
                            >
                                <Stack direction={"row"} spacing={0.5}>
                                    <CustomIcon name="warning" sx={{ bgcolor: "warning.main", width: 80, height: 20, }} />
                                    <Typography variant="subtitle2" sx={{ color: "#000", fontWeight: 400 }}>
                                        Kindly note the private key is your own secure access key for your account.
                                        RealX does not store your private key and therefore will not have access to your account or ability to recover this key.
                                    </Typography>
                                </Stack>
                            </Box>
                        </Stack>
                    </Stack>
                    :
                    <Stack alignItems={"center"} mt={{ md: 15 }} spacing={{ md: 6, xs: 3 }} >
                        <Box
                            sx={{
                                width: { md: 132, xs: 88 }, height: { md: 132, xs: 88 },
                                bgcolor: "primary.light",
                                borderRadius: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                mt: { md: 0, xs: 3 }
                            }}
                        >
                            <CustomIcon name="blockchain" sx={{ bgcolor: "primary.main", width: { md: 70, xs: 32 }, height: { md: 70, xs: 32 }, }} />
                        </Box>
                        <Stack spacing={3}>
                            <Stack alignItems={"center"} spacing={1} sx={{ maxWidth: 408 }} textAlign={"center"}>
                                <Typography variant="h4">Create Your Digital Assets Account</Typography>
                                <Typography variant="subtitle1" sx={{ color: "#000", fontWeight: 400 }}>You'll need to create a new digital assets account to receive the tokens.</Typography>
                            </Stack>
                            <Stack px={{ md: 6 }}>
                                <FormField label={"Enter Nick Name"} inputProps={{
                                    placeholder: "Primary Account",
                                    type: "text",
                                    value: dltName,
                                    onChange: (e) => {
                                        setDltName(e.target.value)
                                    },
                                }} />
                            </Stack>
                            <Stack px={{ md: 6 }}>
                                <LoadingButton loading={loading} onClick={() => createBlockChainAcc()}
                                    variant="contained" sx={{ mb: 1 }}
                                    loadingIndicator={
                                        <CircularProgress size={30} sx={{ color: 'red' }} />
                                    }
                                >
                                    Create Account
                                </LoadingButton>
                            </Stack>
                            <Box
                                sx={{
                                    maxWidth: 408,
                                    bgcolor: "warning.light",
                                    px: "10px",
                                    py: 1,
                                    borderRadius: 0.5,
                                }}
                            >
                                <Stack direction={"row"} spacing={0.5}>
                                    <CustomIcon name="warning" sx={{ bgcolor: "warning.main", width: 80, height: 20, }} />
                                    <Typography variant="subtitle1" sx={{ color: "#000", fontWeight: 400 }}>
                                        Once your account is created, you'll see a private key of your digital assets account.
                                        <span style={{ fontWeight: 600 }}> Please save this key</span> as this is the password to access your digital assets account.
                                        This key is only known to you and RealX does not store or has any knowledge of it.
                                    </Typography>
                                </Stack>
                            </Box>
                        </Stack>
                    </Stack>
            }
        </Box>
    )
}

export default BlockchainAcc;